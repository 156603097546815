import React from "react";
// Generate download dialog
import download from "downloadjs";
// Helper functions
import {
  sendQuery,
  initLogger,
  hasValueChanged,
  dateToString,
} from "common/Helpers";
// PrimeReact components
import { SplitButton } from "primereact/splitbutton";
import { Toast } from "primereact/toast";
// Custom components
import { FormatDialog, SortDialog } from "./";
// Localization
import { injectIntl } from "react-intl";
// Static values
import {
  MESSAGE_KEYS,
  QUERIES,
  MESSAGE_SEVERITY,
} from "assets/staticData/enums";
import { connect } from "react-redux";
import { setPeppolExporterTaskActive } from "actions/index";
import BillPeppolExportDialog from "./BillPeppolExportDialog";
// Logging
const logger = initLogger("bills_tool_button");

class ToolButton extends React.Component {
  state = {
    selectedBills: [],
    displayedBills: [],
    queryString: "",

    selectionBillsPending: false,
    selectionReminderPending: false,
    selectionPaymentPending: false,
    selectionStatusPending: false,
    selectionExportpending: false,
    allReminderPending: false,
    allExportPending: false,
    buttonContent: [],

    sortDialogVisible: false,
    formatDialogVisible: false,
    peppolDialogVisible: false,
  };

  componentDidMount = () => {
    this.initButtonContent();
  };

  componentDidUpdate = (prevProps) => {
    const { selectedBills, queryString, isPeppolTaskActive } = this.props;

    if (
      selectedBills.length !== prevProps.selectedBills.length ||
      hasValueChanged(prevProps.queryString, queryString) ||
      prevProps.isPeppolTaskActive !== isPeppolTaskActive
    ) {
      this.initButtonContent();
    }
  };

  /**
   * @param {String} appendix
   * @returns {String}
   */
  generateFileName = (appendix = "") => {
    let now = new Date();
    return `LA_${dateToString(now, "")}_${appendix}`;
  };

  initButtonContent = () => {
    const {
      selectionBillsPending,
      selectionReminderPending,
      selectionPaymentPending,
      selectionExportpending,
      allReminderPending,
      allExportPending,
      selectionStatusPending,
    } = this.state;
    const {
      intl,
      selectedBills,
      displayedBills,
      queryString,
      setPeppolExporterTaskActive,
      isPeppolTaskActive,
    } = this.props;
    const {
      BILLS_MULTI_BILLING_LABEL,
      BILLS_MULTI_STATUS_LABEL,
      BILLS_MULTI_REPORT_LABEL,
      BILLS_ALL_REMINDER_LABEL,
      BILLS_MULTI_REMINDER_LABEL,
      BILLS_MULTI_EXPORT_LABEL,
      BILLS_ALL_EXPORT_LABEL,
      BILLS_EXPORT_TO_PEPPOL,
    } = MESSAGE_KEYS;

    let buttonContent = [
      {
        icon: `pi ${
          selectionPaymentPending ? "pi-spin pi-spinner" : "pi-calendar-plus"
        }`,
        command: this.props.handleMultiPaymentClick,
        label: intl.formatMessage({
          id: BILLS_MULTI_BILLING_LABEL,
        }),
        disabled: selectedBills.length === 0 || selectionPaymentPending,
      },
      {
        icon: `pi ${
          selectionStatusPending ? "pi-spin pi-spinner" : "pi-sliders-h"
        }`,
        command: this.props.handleMultiStatusClick,
        label: intl.formatMessage({ id: BILLS_MULTI_STATUS_LABEL }),
        disabled: selectedBills.length === 0 || selectionStatusPending,
      },
      {
        icon: `pi ${
          selectionBillsPending ? "pi-spin pi-spinner" : "pi-sort-amount-down"
        }`,
        command: () => {
          this.handleSelectionClick(
            QUERIES.GET_MULTI_BILLS_PDF,
            "selectionBillsPending",
            "bills"
          );
        },
        label: intl.formatMessage({
          id: BILLS_MULTI_REPORT_LABEL,
        }),

        disabled: selectedBills.length === 0 || selectionBillsPending,
      },
      {
        icon: `pi ${
          selectionReminderPending
            ? "pi-spin pi-spinner"
            : "pi-sort-amount-down"
        }`,
        command: () => {
          this.handleSelectionClick(
            QUERIES.GET_WARNING_REPORT,
            "selectionReminderPending",
            "reminders",
            "&state=4"
          );
        },
        label: intl.formatMessage({
          id: BILLS_MULTI_REMINDER_LABEL,
        }),

        disabled: selectedBills.length === 0 || selectionReminderPending,
      },
      {
        icon: `pi ${
          selectionExportpending ? "pi-spin pi-spinner" : "pi-sort-amount-up"
        }`,
        command: () => {
          this.setState({ formatDialogVisible: true });
        },
        label: intl.formatMessage({
          id: BILLS_MULTI_EXPORT_LABEL,
        }),

        disabled: selectedBills.length === 0 || selectionExportpending,
      },
      {
        separator: true,
        disabled: true,
      },
      {
        icon: `pi ${allReminderPending ? "pi-spin pi-spinner" : "pi-inbox"}`,
        label: intl.formatMessage({
          id: BILLS_ALL_REMINDER_LABEL,
        }),
        command: () => {
          this.handleFilterClick(
            QUERIES.GENERATE_REMINDER_FOR_FILTERED,
            "allReminderPending",
            "reminders"
          );
        },
        disabled:
          !displayedBills || displayedBills.length < 1 || allReminderPending,
      },
      {
        icon: `pi ${allExportPending ? "pi-spin pi-spinner" : "pi-inbox"}`,
        label: intl.formatMessage({
          id: BILLS_ALL_EXPORT_LABEL,
        }),
        command: () => {
          this.setState({ sortDialogVisible: true });
        },
        disabled:
          !displayedBills || displayedBills.length < 1 || allExportPending,
      },
      {
        icon: `pi ${
          isPeppolTaskActive ? "pi-spin pi-spinner" : "pi-file-export"
        }`,
        label: intl.formatMessage({
          id: BILLS_EXPORT_TO_PEPPOL,
        }),
        disabled: isPeppolTaskActive,
        command: () => {
          //setPeppolExporterTaskActive(true);
          this.setState({ peppolDialogVisible: true });
        },
      },
    ];
    this.setState({
      buttonContent,
      queryString,
      displayedBills,
      selectedBills,
    });
  };

  /**
   * Handle file download using the selected bills.
   *
   * @param {String} query The query to be executed.
   * @param {String} pendingKey The state key name of the respective pending value.
   * @param {String} fileName The name of the downloaded file without extendsion.
   * @param {String} additionalParams Additional query parameters. Defaults to empty string.
   * @param {String} format defines the file format of the downloaded data. Defaults to pdf.
   */
  handleSelectionClick = (
    query,
    pendingKey,
    fileName,
    additionalParams = "",
    format = "PDF",
    isHospital
  ) => {
    const { selectedBills } = this.state;
    const { intl } = this.props;
    const { ERROR_DATA_FETCH, BILLS_GENERATING_MESSAGE } = MESSAGE_KEYS;
    if (!this.state[pendingKey] && selectedBills && selectedBills.length >= 1) {
      try {
        let queryParams = [];
        selectedBills.forEach((bill) => {
          queryParams.push(bill.transactionId);
        });
        logger.info("PENDING", { [pendingKey]: true });
        this.setState({ [pendingKey]: true });
        this.toast.show({
          severity: MESSAGE_SEVERITY.INFO,
          summary: intl.formatMessage({
            id: BILLS_GENERATING_MESSAGE,
          }),
        });
        sendQuery(
          `${query}${queryParams.toString()}${additionalParams}&xlsFormat=${
            format === "XLSX" ? "true" : "false"
          }${isHospital ? isHospital : ""}`,
          "GET",
          null,
          "blob"
        )
          .then(
            (response) => {
              if (response) {
                download(
                  response,
                  `${this.generateFileName(fileName)}.${format.toLowerCase()}`,
                  `application/${format.toLowerCase()}`
                );
              }
            },
            (error) => {
              this.toast.show({
                severity: MESSAGE_SEVERITY.ERROR,
                summary:
                  typeof error === "string"
                    ? error
                    : intl.formatMessage({
                        id: ERROR_DATA_FETCH,
                      }),
              });
            }
          )
          .finally(() => {
            this.setState({ [pendingKey]: false });
          });
      } catch (reminderException) {
        logger.warn("Exception on generate reminder", reminderException);
        this.toast.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: intl.formatMessage({
            id: ERROR_DATA_FETCH,
          }),
        });
      }
    }
  };

  /**
   * Handle file download using the query string parameter.
   *
   * @param {String} query The query to be executed.
   * @param {String} pendingKey The state key name of the respective pending value.
   * @param {String} fileName The name of the downloaded file without extendsion.
   * @param {Number} sortOrder Optional value defining the sort order (null = none, 1 = date asc, 2 = date desc, 3 = customer name asc). Defaults to null
   * @param {String} format Optional value to determine the file type of the downloaded file. (PDF or XLSX)
   */
  handleFilterClick = (
    query,
    pendingKey,
    fileName,
    sortOrder,
    format = "PDF",
    isHospital
  ) => {
    const { displayedBills, queryString } = this.state;
    const { intl } = this.props;
    const { ERROR_DATA_FETCH, BILLS_GENERATING_MESSAGE } = MESSAGE_KEYS;
    if (
      !this.state[[pendingKey]] &&
      displayedBills &&
      displayedBills.length >= 1
    ) {
      try {
        this.setState({ [pendingKey]: true });
        this.toast.show({
          severity: MESSAGE_SEVERITY.INFO,
          summary: intl.formatMessage({
            id: BILLS_GENERATING_MESSAGE,
          }),
        });
        sendQuery(
          `${query}${queryString}${
            sortOrder ? `&orderExport=${sortOrder}` : ""
          }&xlsFormat=${format === "XLSX" ? "true" : "false"}${
            isHospital ? isHospital : ""
          }`,
          "GET",
          null,
          "blob"
        ).then(
          (response) => {
            if (response) {
              download(
                response,
                `${this.generateFileName(fileName)}.${format.toLowerCase()}`,
                `application/${format.toLowerCase()}`
              );
            }
            this.setState({ [pendingKey]: false });
          },
          (error) => {
            this.toast.show({
              severity: MESSAGE_SEVERITY.ERROR,
              summary:
                typeof error === "string"
                  ? error
                  : intl.formatMessage({
                      id: ERROR_DATA_FETCH,
                    }),
            });
            this.setState({ [pendingKey]: false });
          }
        );
      } catch (reminderException) {
        logger.warn(
          "Exception on generate reminder",
          reminderException,
          queryString
        );
        this.toast.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: intl.formatMessage({
            id: ERROR_DATA_FETCH,
          }),
        });
        this.setState({ [pendingKey]: false });
      }
    }
  };

  splitButtonTemplate = (label, count = 0) => {
    try {
      let badge = <></>;
      if (count > 0) {
        badge = <span className="p-badge">{count}</span>;
      }
      return (
        <div>
          {label} {badge}
        </div>
      );
    } catch (renderException) {
      logger.warn(renderException);
      return (
        <div>
          {this.props.intl.formatMessage({ id: MESSAGE_KEYS.ERROR_RENDER })}
        </div>
      );
    }
  };

  render = () => {
    const {
      buttonContent,
      selectedBills,
      sortDialogVisible,
      formatDialogVisible,
      peppolDialogVisible,
    } = this.state;
    const { disabled, handleNewClick } = this.props;

    return (
      <div>
        <Toast ref={(el) => (this.toast = el)} />
        <SortDialog
          visible={sortDialogVisible}
          onHide={() => {
            this.setState({ sortDialogVisible: false });
          }}
          onAccept={(sortOrder, format, isHospital) => {
            this.handleFilterClick(
              QUERIES.GET_FILTERED_BILL_EXPORT,
              "allExportPending",
              "export",
              sortOrder,
              format,
              isHospital
            );
          }}
        />
        <FormatDialog
          visible={formatDialogVisible}
          onHide={() => {
            this.setState({ formatDialogVisible: false });
          }}
          onAccept={(format, isHospital) => {
            this.handleSelectionClick(
              QUERIES.GET_BILL_EXPORT,
              "selectionExportpending",
              "bill",
              "",
              format,
              isHospital
            );
          }}
        />
        <BillPeppolExportDialog
          visible={peppolDialogVisible}
          onHide={() => {
            this.setState({ peppolDialogVisible: false });
          }}
        />
        <span className="p-overlay-badge">
          <SplitButton
            id="bills_tool_button"
            label=""
            icon="pi pi-plus"
            model={buttonContent}
            disabled={disabled}
            onClick={handleNewClick}
          />
          {selectedBills.length && selectedBills.length > 0 ? (
            <span className="p-badge p-badge-secondary">
              {selectedBills.length}
            </span>
          ) : (
            <></>
          )}
        </span>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    isPeppolTaskActive: state.application?.isPeppolExporterTaskActive,
  };
};

export default connect(mapStateToProps, { setPeppolExporterTaskActive })(
  injectIntl(ToolButton)
);
