import React from "react";
// Redux
import { connect } from "react-redux";
import { setChangePending } from "actions/sessionActions";
// Responsive
import { isDesktop, withOrientationChange } from "react-device-detect";
// Localization
import { injectIntl } from "react-intl";
// Primereact components
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";
import { Inplace, InplaceContent, InplaceDisplay } from "primereact/inplace";
import { ToggleButton } from "primereact/togglebutton";
import { Dropdown } from "primereact/dropdown";
// Static values
import { BILL_STATUS, TRANSPORT_TYPES } from "assets/staticData/combodata";
import {
  MESSAGE_KEYS,
  MESSAGE_SEVERITY,
  NETWORK_ERROR_CODES,
  QUERIES,
} from "assets/staticData/enums";
// Custom components
import { BillPositionEditLayout, AddPositionDialog } from "./";
import {
  FloatingTextInput,
  TranslatedCB,
  CustomerSelector,
  AddressSelector,
  SplitDateTimeInput,
  /*CheckboxText,*/
} from "components/common";
// Helper functions
import {
  sendQuery,
  initLogger,
  dateToBillNumber,
  equalObjects,
  equalDates,
  /*isAdmin,*/
  hasValueChanged,
  dateToISOString,
  addressToString,
  valiDate,
  safeRender,
} from "common/Helpers";
// Tippy tooltip
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { PeppolView } from "./index";
// Logging
const logger = initLogger("bill_edit_layout");

const EMPTY_TRANSACTION = { transactionDetailsId: new Date() * -1 };

const EMPTY_STATE = Object.freeze({
  inputBillNumber: "",
  inputPassengerName: "",
  inputBillStatus: BILL_STATUS[0],
  inputBillingAddress: null,
  inputCustomer: null,

  inputPhone: "",
  inputMobile: "",

  inputTransportType: TRANSPORT_TYPES[1],
  inputTransportDate: new Date(),
  inputDeparture: "",
  inputDestination: "",
  inputCommentInternal: "",
  inputCommentExternal: "",
  inputPositions: [],

  inputOrderDate: null,
  inputReminder1Date: null,
  inputReminder2Date: null,
  inputDASDate: null,

  inputHospitalOrderNumber: null,
  inputHospitalType: null,
  inputHospitalPosition: null,

  inputActive: true,
  inputTotalAmount: 0,

  inputTransportTicket: true,
  inputReminderUpdate: null,

  legacyFromName: "",
  legacyToName: "",
  legacyFromAddress: "",
  legacyToAddress: "",

  positionIndex: null,
  positionDialogVisible: false,

  validBillingAddress: null,
  validBillingDate: null,

  validFromAddress: null,
  validToAddress: null,

  savePending: false,

  positionPresets: [],
  presetFetchPending: false,

  addressDisplay: "",
  peppolSendDate: "",
  peppolState: "",
  peppolTransferSuccess: "",
});

class BillEditLayout extends React.Component {
  state = {
    ...EMPTY_STATE,
  };

  componentDidMount = () => {
    this.initInputs();
  };

  componentDidUpdate = (prevProps) => {
    try {
      let newId = this.props.selectedBill?.transactionId
        ? this.props.selectedBill.transactionId
        : -1;
      let oldId = prevProps?.selectedBill?.transactionId
        ? prevProps.selectedBill.transactionId
        : -1;
      if (!prevProps.selectedBill || newId !== oldId) {
        this.initInputs();
      }
    } catch (dumException) {
      logger.warn(dumException);
    }
  };

  fetchPresets = () => {
    return new Promise((resolve, reject) => {
      try {
        return sendQuery(QUERIES.GET_PAYMENT_PRESETS, "get").then(
          (response) => {
            if (response?.content) {
              resolve(response.content);
            } else {
              logger.warn("Presets response is empty", response);
              reject();
            }
          },
          (error) => {
            logger.warn("ERROR", error);
            reject(error);
          }
        );
      } catch (fetchException) {
        logger.warn(fetchException);
        reject(fetchException);
      }
    });
  };

  /**
   * Custom compare function used to sort the bill positions table.
   * Returns -1 if the first object's position in lower than the second's, 1 if it's the other way around.
   * Returns 0 if both positions are equal.
   *
   * @param {Object} posA
   * @param {Number} posA.position The first object's position in the table.
   * @param {Object} posB
   * @param {Number} posB.position The second object's position in the table.
   */
  comparePositions = (posA, posB) => {
    if (posA.position < posB.position) {
      return -1;
    } else if (posA.position > posB.position) {
      return 1;
    } else {
      return 0;
    }
  };

  initInputs = (serverResponse = null) => {
    const { selectedBill } = this.props;
    if (selectedBill && selectedBill.transactionId > 0) {
      // Load selected bill.
      const {
        invoiceNumber,
        transportType,
        orderDate,
        transactionDetails,
        remark,
        info,
        invoiceAddress,
        customer,
        name,

        fromAddress,
        toAddress,
        fromAddressInfo,
        toAddressInfo,
        fromAddressId,
        toAddressId,

        transportDate,
        firstPaymentReminderDate,
        secondPaymentReminderDate,
        sylvainDate,

        reminderLastUpdate,

        hospitalOrderNumber,
        hospitalType,
        hospitalPosition,

        totalPrice,

        active,

        transportTicket,

        customerAddress,
      } = serverResponse ? serverResponse : selectedBill;

      // Set status to not set if state is null, find & set respective state by ID else.
      let inputBillStatus = selectedBill.state
        ? BILL_STATUS.find((status) => {
            return status.billStatusId === selectedBill.state;
          })
        : BILL_STATUS[0];

      // Remove inactive positions and check list order.
      let inputPositions = [
        ...(transactionDetails
          ? transactionDetails.sort(this.comparePositions).filter((row) => {
              return row.active === true;
            })
          : []),
      ];
      // Clear empty product presets. (Legacy issue)
      inputPositions.forEach((posi) => {
        const { product } = posi;
        if (product?.productId === null) {
          posi.product = null;
        }
      });

      this.setState({
        inputBillNumber: invoiceNumber ? invoiceNumber : "",
        inputPassengerName: name ? name : "",
        inputBillStatus,
        inputBillingAddress: invoiceAddress?.addressId
          ? { ...invoiceAddress }
          : null,
        inputCustomer: customer?.personId ? { ...customer } : null,
        inputPhone: customer?.phoneHome ? customer.phoneHome : "",
        inputMobile: customer?.gsm ? customer.gsm : "",
        inputTransportType: transportType
          ? TRANSPORT_TYPES.find((type) => {
              return type.transportTypeId === transportType;
            })
          : TRANSPORT_TYPES[0],
        inputTransportDate: transportDate ? new Date(transportDate) : null,
        inputDeparture: fromAddressId
          ? { addressId: fromAddressId, line1: fromAddress ? fromAddress : "" }
          : null,
        inputDestination: toAddressId
          ? { addressId: toAddressId, line1: toAddress ? toAddress : "" }
          : null,
        inputCommentInternal: remark ? remark.replace("€", "\u20ac") : "",
        inputCommentExternal: info ? info.replace("€", "\u20ac") : "",
        inputPositions,
        savePending: false,

        inputDASDate: sylvainDate ? new Date(sylvainDate) : null,
        inputOrderDate: orderDate ? new Date(orderDate) : null,
        inputReminder1Date: firstPaymentReminderDate
          ? new Date(firstPaymentReminderDate)
          : null,
        inputReminder2Date: secondPaymentReminderDate
          ? new Date(secondPaymentReminderDate)
          : null,

        inputHospitalOrderNumber: hospitalOrderNumber
          ? hospitalOrderNumber
          : null,
        inputHospitalType: hospitalType ? hospitalType : null,
        inputHospitalPosition: hospitalPosition ? hospitalPosition : null,
        inputActive: active === true,
        inputTransportTicket: transportTicket === true,

        inputTotalAmount: totalPrice ? totalPrice : 0.0,

        legacyFromName: fromAddress ? fromAddress : "",
        legacyToName: toAddress ? toAddress : "",
        legacyFromAddress: fromAddressInfo,
        legacyToAddress: toAddressInfo,

        inputReminderUpdate: reminderLastUpdate
          ? new Date(reminderLastUpdate)
          : null,

        addressDisplay: customerAddress ? customerAddress : "",
      });
    } else {
      this.setState({
        ...EMPTY_STATE,
        transactionId: null,
        inputCustomer:
          selectedBill &&
          typeof selectedBill !== "number" &&
          selectedBill.customer &&
          selectedBill.customer.personId
            ? { ...selectedBill.customer }
            : null,
        positionPresets: this.state.positionPresets,
        transportDate: new Date(),
        inputOrderDate: new Date(),
      });
    }
    if (this.state.positionPresets.length === 0) {
      this.setState(
        {
          presetFetchPending: true,
        },
        () => {
          this.fetchPresets().then(
            (positionPresets) => {
              // Return active values only.
              this.setState({
                positionPresets: positionPresets.filter((preset) => {
                  return preset.active === true;
                }),
              });
            },
            (error) => {
              logger.warn("Error on fetch presets", error);
            }
          );
        }
      );
    }
  };

  validateInputs = () => {
    return new Promise((resolve, reject) => {
      try {
        const { intl } = this.props;
        const {
          BILLS_VALIDATION_ERROR_BILLING_DATE,
          BILLS_VALIDATION_ERROR_ADDRESS,
          BILLS_VALIDATION_ERROR_FROM_TEXT,
          BILLS_VALIDATION_ERROR_TO_TEXT,
        } = MESSAGE_KEYS;
        const { ERROR } = MESSAGE_SEVERITY;
        const {
          inputBillingAddress,
          inputTransportDate,
          legacyFromName,
          legacyToName,
        } = this.state;

        let validationErrors = [];

        let validBillingAddress = inputBillingAddress?.addressId !== null;

        if (!validBillingAddress) {
          validationErrors.push({
            severity: ERROR,
            summary: intl.formatMessage({ id: BILLS_VALIDATION_ERROR_ADDRESS }),
          });
        }
        let validBillingDate =
          !inputTransportDate || !isNaN(inputTransportDate.getTime());
        if (!validBillingDate) {
          validationErrors.push({
            severity: ERROR,
            summary: intl.formatMessage({
              id: BILLS_VALIDATION_ERROR_BILLING_DATE,
            }),
          });
        }

        let validFromAddress = legacyFromName && legacyFromName.length > 0;
        if (!validFromAddress) {
          validationErrors.push({
            severity: ERROR,
            summary: intl.formatMessage({
              id: BILLS_VALIDATION_ERROR_FROM_TEXT,
            }),
          });
        }
        let validToAddress = legacyToName && legacyToName.length > 0;
        if (!validToAddress) {
          validationErrors.push({
            severity: ERROR,
            summary: intl.formatMessage({
              id: BILLS_VALIDATION_ERROR_TO_TEXT,
            }),
          });
        }

        this.setState({
          validBillingAddress,
          validBillingDate,
          validFromAddress,
          validToAddress,
        });

        if (validationErrors.length === 0) {
          resolve(this.mapInputsToDTO());
        } else {
          reject(validationErrors);
        }
      } catch (validationException) {
        logger.error(validationException);
        reject({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: this.props.intl.formatMessage({ id: MESSAGE_KEYS.ERROR }),
        });
      }
    });
  };

  /**
   * Checks if the current inputs are different from the selected object.
   *
   * @param {String} newKey
   * @param {Any} newValue
   */
  checkChangePending = (newKey, newValue) => {
    const {
      selectedBill: {
        invoiceNumber,
        invoiceAddress,
        customer,
        orderDate,
        transportType,
        fromAddressId,
        toAddressId,
        remark,
        info,
        transportTicket,
        hospitalOrderNumber,
        hospitalType,
        hospitalPosition,
        reminderLastUpdate,
      },
      setChangePending,
    } = this.props;
    let isPending = false;
    try {
      let bill = { ...this.state };
      bill[newKey] = newValue;

      const {
        inputBillNumber,
        inputBillingAddress,
        inputTransportDate,
        inputCommentInternal,
        inputCommentExternal,
        inputDeparture,
        inputDestination,
        inputTransportType,
        inputCustomer,
        inputTransportTicket,
        inputHospitalOrderNumber,
        inputHospitalType,
        inputHospitalPosition,
        inputPhone,
        inputMobile,
        inputReminderUpdate,
      } = bill;

      const { gsm = "", phoneHome = "" } = customer;

      isPending =
        hasValueChanged(invoiceNumber, inputBillNumber) ||
        !equalObjects(inputCustomer, customer, "personId") ||
        !equalObjects(inputBillingAddress, invoiceAddress, "addressId") ||
        !equalDates(inputTransportDate, new Date(orderDate)) ||
        transportType !==
          (inputTransportType ? inputTransportType.transportTypeId : null) ||
        fromAddressId !== (inputDeparture ? inputDeparture.addressId : null) ||
        toAddressId !==
          (inputDestination ? inputDestination.addressId : null) ||
        hasValueChanged(remark, inputCommentInternal) ||
        hasValueChanged(info, inputCommentExternal) ||
        hasValueChanged(transportTicket, inputTransportTicket) ||
        hasValueChanged(phoneHome, inputPhone) ||
        hasValueChanged(hospitalOrderNumber, inputHospitalOrderNumber) ||
        hasValueChanged(hospitalType, inputHospitalType) ||
        hasValueChanged(hospitalPosition, inputHospitalPosition) ||
        hasValueChanged(gsm, inputMobile) ||
        hasValueChanged(reminderLastUpdate, inputReminderUpdate);
    } catch (changeException) {
      logger.warn("Exception on check change pending", changeException);
      isPending = true;
    } finally {
      setChangePending(isPending);
    }
  };

  /**
   * Maps the current input values into a backend DTO.
   * If a new key/value pair is supplied, it will be added to the returned object.
   *
   * @param {String} newKey
   * @param {Any} newValue
   */
  mapInputsToDTO = () => {
    const {
      inputBillNumber,
      inputBillingAddress,
      inputTransportDate,
      inputCommentInternal,
      inputCommentExternal,
      inputDeparture,
      inputDestination,
      inputTransportType,
      inputCustomer,
      inputPositions,
      inputBillStatus,
      inputPassengerName,
      inputTransportTicket,
      inputHospitalOrderNumber,
      inputHospitalType,
      inputHospitalPosition,

      inputDASDate,
      inputOrderDate,
      inputReminder1Date,
      inputReminder2Date,

      inputReminderUpdate,

      legacyFromName,
      legacyToName,

      inputActive,
    } = this.state;
    // Validation successful, generate DTO.
    const { selectedBill } = this.props;

    let newOrderDate = inputOrderDate ? dateToISOString(inputOrderDate) : null;

    let newTransportDate = inputTransportDate
      ? dateToISOString(inputTransportDate)
      : null;

    let bill = {
      ...selectedBill,
      state: inputBillStatus.billStatusId,
      invoiceNumber: inputBillNumber,
      hospitalOrderNumber: inputHospitalOrderNumber,
      hospitalType: inputHospitalType,
      hospitalPosition: inputHospitalPosition,
      customer: inputCustomer?.personId ? inputCustomer : null,
      orderDate: newOrderDate,
      invoiceAddress: inputBillingAddress?.addressId
        ? inputBillingAddress
        : null,
      transportType: inputTransportType?.transportTypeId
        ? inputTransportType.transportTypeId
        : 0,
      remark: inputCommentInternal ? inputCommentInternal : "",
      info: inputCommentExternal ? inputCommentExternal : "",
      fromAddressId: inputDeparture?.addressId
        ? inputDeparture.addressId
        : null,
      toAddressId: inputDestination?.addressId
        ? inputDestination.addressId
        : null,
      transactionDetails: inputPositions ? [...inputPositions] : [],
      transactionId:
        selectedBill.transactionId > 0 ? selectedBill.transactionId : null,
      name: inputPassengerName ? inputPassengerName : "",

      transportDate: newTransportDate,
      firstPaymentReminderDate: inputReminder1Date
        ? dateToISOString(inputReminder1Date)
        : null,
      secondPaymentReminderDate: inputReminder2Date
        ? dateToISOString(inputReminder2Date)
        : null,
      sylvainDate: inputDASDate ? dateToISOString(inputDASDate) : null,

      active:
        inputActive !== null && inputActive !== undefined ? inputActive : true,
      transportTicket: inputTransportTicket === true,

      fromAddress: legacyFromName,
      toAddress: legacyToName,

      reminderLastUpdate: inputReminderUpdate,
    };
    //FIXME: why is this required to get the editDTO transaction to work?
    bill.transactionPeppolLogs = null;
    return bill;
  };

  /**
   * Sends the modified data to the server.
   * @param {BillData} data
   * @returns {Promise<Object|String>}
   */
  sendData = (data) => {
    return new Promise((resolve, reject) => {
      try {
        sendQuery(QUERIES.EDIT_BILL, "post", data).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
        // TODO Handle offline action
      } catch (queryException) {
        logger.error(queryException);
        reject(queryException);
      }
    });
  };

  updateCustomer = () => {
    return new Promise((resolve, reject) => {
      try {
        const { inputCustomer, inputPhone, inputMobile } = this.state;
        if (inputCustomer && inputCustomer.personId) {
          let data = {
            customer: {
              ...inputCustomer,
              phoneHome: inputPhone,
              gsm: inputMobile,
            },
          };
          sendQuery(QUERIES.EDIT_CUSTOMER_PHONE, "post", data).then(
            () => {
              resolve();
            },
            (error) => {
              reject(error);
            }
          );
        } else {
          resolve();
        }
      } catch (updateException) {
        reject(updateException);
      }
    });
  };

  handleSaveClick = async () => {
    const { intl, handleParentUpdate } = this.props;
    this.setState({
      savePending: true,
    });
    this.validateInputs().then(
      (newData) => {
        this.sendData(newData).then(
          async (response) => {
            if (response) {
              const { transactionId = -1, invoiceNumber = "-1" } = response;

              // Update customer phones.
              this.updateCustomer().then(
                () => {
                  if (this.toast) {
                    this.toast.show({
                      severity: MESSAGE_SEVERITY.SUCCESS,
                      summary: intl.formatMessage({
                        id: MESSAGE_KEYS.CUSTOMERS_SAVE_SUCCESS_MESSAGE,
                      }),
                    });
                  }

                  // Merge responses & udpate FE.
                  const { inputPhone, inputMobile } = this.state;
                  let mergedResponse = {
                    ...response,
                    customer: {
                      ...response.customer,
                      phoneHome: inputPhone,
                      gsm: inputMobile,
                    },
                  };
                  this.setState(
                    {
                      savePending: false,
                      inputBillNumber: invoiceNumber,
                      selectedBill: mergedResponse,
                    },
                    () => {
                      this.initInputs(mergedResponse);
                      this.updateTotalAmount(mergedResponse.transactionDetails);
                    }
                  );

                  handleParentUpdate(transactionId, invoiceNumber);
                },
                (error) => {
                  logger.error(error);
                  this.setState({ savePending: false });
                }
              );
            }
          },
          (error) => {
            logger.error(error);
            let summary;
            if (error === NETWORK_ERROR_CODES.DUPLICATE_ERROR) {
              summary = this.props.intl.formatMessage(
                {
                  id: MESSAGE_KEYS.ERROR_DUPLICATE,
                },
                { value: this.state.inputBillNumber }
              );
            } else {
              summary = this.props.intl.formatMessage({
                id: MESSAGE_KEYS.ERROR_DATA_SAVE,
              });
            }
            this.toast.show({
              severity: MESSAGE_SEVERITY.ERROR,
              summary,
            });
            this.setState({ savePending: false });
          }
        );
      },
      (validationErrors) => {
        this.toast.show(validationErrors);
        this.setState({ savePending: false });
      }
    );
  };

  /**
   * Deletes the selected entry from the positions table.
   * New unsaved rows will be removed from the array, existing rows will have their active value set to false.
   * Sets redux changePending-value to true.
   *
   * @param {Number} deleteIndex The array index of the deleted row.
   */
  handlePositionDelete = (deleteIndex) => {
    let inputPositions = [...this.state.inputPositions];
    try {
      if (deleteIndex >= 0) {
        if (!inputPositions[deleteIndex].transactionDetailsId) {
          // New row, delete it.
          inputPositions.splice(deleteIndex, 1);
        } else {
          // Existing row, set active to false.
          inputPositions[deleteIndex].active = false;
        }

        this.props.setChangePending(true);
        this.handleRowReorder(inputPositions);
      }
    } catch (deleteException) {
      logger.warn(
        "Exception on handle position delete",
        deleteException,
        deleteIndex
      );
    } finally {
      this.updateTotalAmount(inputPositions);
    }
  };

  /**
   * Is called after reordering a bill position row.
   * Updates payment position position-value based on their new index within the table and stores the new array in the component state.
   *
   * @param {Array<Object>} reorderedList The reordered list received by the reorder event.
   */
  handleRowReorder = (reorderedList) => {
    let inputPositions = [];
    for (let c = 0; c < reorderedList.length; c++) {
      let position = reorderedList[c];
      position.position = c + 1;
      inputPositions.push(position);
    }
    this.setState({ inputPositions });
  };

  /**
   * Calculates & updates the new total amount based on the new positions list.
   *
   * @param {Array<Object>} newPositionList The updated list of positions.
   */
  updateTotalAmount = (newPositionList) => {
    let inputTotalAmount = 0.0;
    try {
      if (newPositionList?.length) {
        newPositionList.forEach((position) => {
          const { price, amount, totalPrice, active } = position;
          if (active) {
            inputTotalAmount += totalPrice
              ? totalPrice
              : (price ? price : 0) * (amount ? amount : 0);
          }
        });
      }
    } catch (totalException) {
      logger.warn("Exception on update total amount", newPositionList);
    } finally {
      this.setState(
        {
          inputTotalAmount,
        },
        () => {
          this.props.updateParentTotal(newPositionList);
        }
      );
    }
  };
  /**
   * Fetches base & invoice address of the selected customer if supplied.
   * If only base address is available, set billing & departure address to base address.
   * If only invoice address is available, set billing address.
   * If both are available, set departure address to base- & billing address to invoice address
   *
   * @param {Object} addresses
   * @param {Number} addresses.addressId
   * @param {Number} addresses.invoiceAddressId
   */
  updateCustomerAddress = (addresses) => {
    const { addressId = null, invoiceAddressId = null } = addresses;
    // Fetch base address and update state if found.
    if (addressId) {
      try {
        sendQuery(`${QUERIES.GET_ADDRESS_BY_ID}${addressId}`, "GET").then(
          (response) => {
            if (response?.address) {
              const { address } = response;
              let newState = {
                inputDeparture: address,
                legacyFromAddress: addressToString(address),
                legacyFromName: address.searchkey
                  ? address.searchkey
                  : address.countryProvince,
              };
              if (!invoiceAddressId) {
                newState.inputBillingAddress = address;
              }
              this.setState({ ...newState }, () => {
                if (!invoiceAddressId) {
                  this.checkChangePending("inputBillingAddress", address);
                }
                this.checkChangePending("inputDeparture", address);
                this.checkChangePending("legacyFromName", address.name);
              });
            } else {
              this.toast.show({
                severity: MESSAGE_SEVERITY.ERROR,
                summary: this.props.intl.formatMessage({
                  id: MESSAGE_KEYS.ERROR_NO_DATA,
                }),
              });
            }
          },
          (error) => {
            logger.warn(
              "Error on update customer base address",
              error,
              addressId
            );
            this.toast.show({
              severity: MESSAGE_SEVERITY.ERROR,
              summary: this.props.intl.formatMessage({
                id: MESSAGE_KEYS.ERROR_DATA_FETCH,
              }),
            });
          }
        );
      } catch (updateException) {
        logger.warn(
          "Exception on update customer address",
          updateException,
          addressId
        );
      }
    }
    // Fetch billing address and update state if found.
    if (invoiceAddressId) {
      sendQuery(`${QUERIES.GET_ADDRESS_BY_ID}${invoiceAddressId}`, "GET").then(
        (response) => {
          if (response?.address) {
            this.setState(
              {
                inputBillingAddress: response.address,
              },
              this.checkChangePending("inputBillingAddress", response.address)
            );
          }
        },
        (error) => {
          logger.warn(
            "Error on update customer billing address",
            error,
            addressId
          );
          this.toast.show({
            severity: MESSAGE_SEVERITY.ERROR,
            summary: this.props.intl.formatMessage({
              id: MESSAGE_KEYS.ERROR_DATA_FETCH,
            }),
          });
        }
      );
    }
  };

  /**
   * Is called after creating or editing a bill position.
   * If the rowindex is set, the row at the selected index will be updated, a new row will be added else.
   *
   * @param {Object} newRow
   */
  handleRowAdd = (newRow) => {
    const { positionIndex } = this.state;
    const { selectedBill } = this.props;
    const { inputDescription, inputAmount, inputQuantity, inputPreset } =
      newRow;
    let inputPositions = [...this.state.inputPositions];
    try {
      if (positionIndex !== null) {
        inputPositions[positionIndex] = {
          ...inputPositions[positionIndex],
          productName: inputDescription,
          price: inputAmount,
          amount: inputQuantity,
          totalPrice:
            (inputAmount ? inputAmount : 0) *
            (inputQuantity ? inputQuantity : 0),
          position: positionIndex + 1,
          transactionId: selectedBill ? selectedBill.transactionId : null,
          product: !inputPreset?.productId ? inputPreset : null,
          active: true,
          tax: null,
          taxId: null,
        };
      } else {
        let mappedRow = {
          transactionDetailsId: null,
          productName: inputDescription,
          price: inputAmount,
          amount: inputQuantity,
          totalPrice:
            (inputAmount ? inputAmount : 0) *
            (inputQuantity ? inputQuantity : 0),
          position: this.state.inputPositions.length + 1,
          transactionId: selectedBill ? selectedBill.transactionId : null,
          product: inputPreset ? inputPreset : null,
          active: true,
          tax: null,
          taxId: null,
        };
        inputPositions.push(mappedRow);
      }
    } catch (addException) {
      logger.warn("Exception on handle row add", newRow);
    } finally {
      this.setState({
        inputPositions,
        positionDialogVisible: false,
        positionIndex: null,
      });
      this.updateTotalAmount(inputPositions);
    }
  };

  renderCustomerWarning = () => {
    const { intl } = this.props;
    const { sylvain, comment } = this.state.inputCustomer;
    const { CUSTOMERS_COMMENT_LABEL, BILLS_DAS_LABEL } = MESSAGE_KEYS;
    return (
      <Tippy
        content={
          <div>
            {sylvain ? (
              <div>{intl.formatMessage({ id: BILLS_DAS_LABEL })}</div>
            ) : null}
            {comment ? (
              <div>{`${intl.formatMessage({
                id: CUSTOMERS_COMMENT_LABEL,
              })}: ${comment}`}</div>
            ) : null}
          </div>
        }
      >
        <div style={{ width: "20px", background: "red" }}> </div>
      </Tippy>
    );
  };

  renderCustomerInfobox = () => {
    const { intl, isLandscape } = this.props;
    const { BILLS_FILTER_CUSTOMER_LABEL } = MESSAGE_KEYS;
    const { inputCustomer, /* legacyFromName */ addressDisplay } = this.state;
    try {
      if (inputCustomer) {
        const { girlName, lastname, firstname /*address*/, sylvain, comment } =
          inputCustomer;
        return (
          <div
            className={`${
              isDesktop || isLandscape ? "p-field col-6" : "bill_edit_row_resp"
            } shadow-1 flex justify-content-between`}
            key="bls_inf_cst p-f"
          >
            <div className="p-2 flex flex-column justify-content-start">
              <div className="font-light">
                {intl.formatMessage({
                  id: BILLS_FILTER_CUSTOMER_LABEL,
                })}
              </div>
              <div>{`${firstname ? firstname : ""} ${girlName} ${
                lastname ? lastname : ""
              }`}</div>
              <div>{addressDisplay ? addressDisplay : "-"}</div>
              {/*<div>{`${legacyFromName ? legacyFromName : "-"}`}</div>*/}
            </div>
            {(sylvain === true || comment) && this.renderCustomerWarning()}
          </div>
        );
      } else {
        return (
          <div className="p-field col-6 bill_edit_row" key="bls_inf_cst"></div>
        );
      }
    } catch (renderException) {
      logger.warn("Exception on render address info box", renderException);
      return (
        <div className="p-field col-6 bill_edit_row" key="bls_inf_cst"></div>
      );
    }
  };

  renderAddressInfobox = () => {
    const { intl, isLandscape } = this.props;
    const { ADDRESS_TYPE_INVOICE } = MESSAGE_KEYS;
    const { inputBillingAddress } = this.state;
    let infoBox;
    try {
      if (inputBillingAddress) {
        const { line1, countryProvince, zipCode, phone1, phone2, remark } =
          inputBillingAddress;
        let phoneLine;
        if (phone1 || phone2 || remark) {
          phoneLine = (
            <div>
              {`${phone1 ? phone1 : ""} ${phone2 ? phone2 : ""} ${
                remark ? remark : ""
              }`}
            </div>
          );
        } else {
          phoneLine = <></>;
        }
        infoBox = (
          <div
            className={`${
              isDesktop || isLandscape ? "p-field col-6" : "bill_edit_row_resp"
            } shadow-1 flex flex-column justify-content-start p-2`}
            key="bls_inf_adr"
          >
            <div className="font-light">
              {intl.formatMessage({ id: ADDRESS_TYPE_INVOICE })}
            </div>
            <div>{line1 ? line1 : "-"}</div>
            <div>{`${zipCode ? zipCode : ""} ${
              countryProvince ? countryProvince : ""
            }`}</div>
            {phoneLine}
          </div>
        );
      } else {
        infoBox = (
          <div className="p-field col-6 bill_edit_row" key="bls_inf_adr"></div>
        );
      }
    } catch (renderException) {
      logger.warn("Exception on render address info box", renderException);
      infoBox = (
        <div className="p-field col-6 bill_edit_row" key="bls_inf_cst"></div>
      );
    } finally {
      return infoBox;
    }
  };

  renderPhoneRow = () => {
    const { intl } = this.props;
    const { USERS_HOME_PHONE_LABEL, USERS_MOBILE_PHONE_LABEL } = MESSAGE_KEYS;
    const { inputPhone, inputMobile, inputCustomer } = this.state;
    if (inputCustomer?.personId) {
      return (
        <div
          className="flex flex-row justify-content-between col-12 mb-2"
          key="p-rw-phone"
        >
          <FloatingTextInput
            id={USERS_MOBILE_PHONE_LABEL}
            value={inputMobile}
            onChange={(e) => {
              this.setState({ inputMobile: e.target.value }, () =>
                this.checkChangePending("inputMobile", e.target.value)
              );
            }}
            label={intl.formatMessage({ id: USERS_MOBILE_PHONE_LABEL })}
            className="mr2"
          />

          <FloatingTextInput
            id={USERS_HOME_PHONE_LABEL}
            value={inputPhone}
            onChange={(e) => {
              this.setState({ inputPhone: e.target.value }, () =>
                this.checkChangePending("inputPhone", e.target.value)
              );
            }}
            label={intl.formatMessage({ id: USERS_HOME_PHONE_LABEL })}
          />
        </div>
      );
    } else {
      return <></>;
    }
  };

  renderInfoboxRow = () => {
    let errorMessage = this.props.intl.formatMessage({
      id: MESSAGE_KEYS.ERROR_RENDER,
    });
    const { inputCustomer, inputBillingAddress } = this.state;
    try {
      if (inputCustomer || inputBillingAddress) {
        return [
          this.renderCustomerInfobox(),
          this.renderAddressInfobox(),
          safeRender(this.renderPhoneRow, errorMessage),
        ];
      } else {
        return <></>;
      }
    } catch (renderException) {
      logger.warn("Exception on render infobox row", renderException);
      return <></>;
    }
  };

  renderAddressTextInput = (isFrom = true) => {
    const { intl } = this.props;
    const {
      BILLS_DEPARTURE_TEXT_LABEL,
      BILLS_DESTINATION_TEXT_LABEL,
      ERROR_RENDER,
    } = MESSAGE_KEYS;
    const { legacyFromName, legacyToName, validFromAddress, validToAddress } =
      this.state;
    try {
      let label = isFrom
        ? BILLS_DEPARTURE_TEXT_LABEL
        : BILLS_DESTINATION_TEXT_LABEL;
      let value = isFrom ? legacyFromName : legacyToName;
      let valid = isFrom ? validFromAddress : validToAddress;

      return (
        <FloatingTextInput
          id={label}
          value={value}
          onChange={(e) => {
            let newStateVal = isFrom
              ? { legacyFromName: e.target.value }
              : { legacyToName: e.target.value };
            this.setState(newStateVal, () =>
              this.checkChangePending(
                `legacy${isFrom ? "From" : "To"}Name`,
                e.target.value
              )
            );
          }}
          label={intl.formatMessage({ id: label })}
          isValid={valid}
          boldInput
        />
      );
    } catch (renderException) {
      logger.warn("Exception on render address input", renderException, isFrom);
      return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
    }
  };

  renderEditInputs = () => {
    const { intl, isLandscape /*userRoles */ } = this.props;
    const {
      BILLS_BILL_NUMBER_LABEL,
      BILLS_DEPARTURE_LABEL,
      BILLS_DESTINATION_LABEL,
      BILLS_COMMENT_INTERNAL_LABEL,
      BILLS_COMMENT_EXTERNAL_LABEL,

      /* NEW INPUTS */
      BILLS_HOSPITAL_ORDER_NUMBER_LABEL,

      BILLS_HOSPITAL_NB_ROUTES_LABEL,
      BILLS_HOSPITAL_NB_ROUTES_SINGLE_LABEL,
      BILLS_HOSPITAL_NB_ROUTES_TWO_WAY_LABEL,
      BILLS_HOSPITAL_NB_ROUTES_MULTIPLE_LABEL,

      BILLS_HOSPITAL_POSITION_LABEL,
      BILLS_HOSPITAL_POSITION_AMBULANCE_LABEL,
      BILLS_HOSPITAL_POSITION_CADDY_LABEL,
      BILLS_HOSPITAL_POSITION_TAXI_LABEL,

      ADDRESS_TYPE_INVOICE,

      BILLS_DAS_DATE_LABEL,
      BILLS_ORDER_DATE_LABEL,
      BILLS_TRANSPORT_DATE_LABEL,
      BILLS_FIRST_REMINDER_DATE_LABEL,
      BILLS_SECOND_REMINDER_DATE_LABEL,
      BILLS_REMINDER_UPDATE_LABEL,

      BILLS_TRANSPORT_TICKET_LABEL,
    } = MESSAGE_KEYS;
    const {
      inputBillNumber,
      inputBillStatus,
      inputBillingAddress,
      inputCustomer,
      inputTransportType,
      inputTransportDate,
      inputDeparture,
      inputDestination,
      inputCommentInternal,
      inputCommentExternal,
      validBillingAddress,
      inputHospitalOrderNumber,
      inputHospitalType,
      inputHospitalPosition,

      inputOrderDate,
      inputDASDate,
      inputReminder1Date,
      inputReminder2Date,

      inputReminderUpdate,

      inputTransportTicket,
    } = this.state;

    /* DROP DOWN */
    const dropDownTrajet = [
      {
        label: intl.formatMessage({
          id: BILLS_HOSPITAL_NB_ROUTES_SINGLE_LABEL,
        }),
        value: "1",
      },
      {
        label: intl.formatMessage({
          id: BILLS_HOSPITAL_NB_ROUTES_TWO_WAY_LABEL,
        }),
        value: "2",
      },
      {
        label: intl.formatMessage({
          id: BILLS_HOSPITAL_NB_ROUTES_MULTIPLE_LABEL,
        }),
        value: "3",
      },
    ];

    const dropDownPosition = [
      {
        label: intl.formatMessage({
          id: BILLS_HOSPITAL_POSITION_AMBULANCE_LABEL,
        }),
        value: "1",
      },
      {
        label: intl.formatMessage({ id: BILLS_HOSPITAL_POSITION_CADDY_LABEL }),
        value: "2",
      },
      {
        label: intl.formatMessage({ id: BILLS_HOSPITAL_POSITION_TAXI_LABEL }),
        value: "3",
      },
    ];

    // Static billnumber display: inputBillNumber / this.props.selectedBill.orderDate.getYear()
    let notResponsive = isDesktop || isLandscape;

    let hasAdminRights = /*isAdmin(userRoles); Not wanted anymore? */ true;

    return (
      <div className={notResponsive ? "p-fluid formgrid grid" : ""}>
        <div className="p-fluid col-12 mb-2 flex justify-content-between align-items-center">
          <div className="p-field flex flex-column justify-content-start">
            <div className="font-light">
              {intl.formatMessage({ id: BILLS_BILL_NUMBER_LABEL })}
            </div>
            <Inplace
              closable
              active={hasAdminRights}
              disabled={!hasAdminRights}
            >
              <InplaceDisplay>
                {`${inputBillNumber} / ${dateToBillNumber(inputTransportDate)}`}
              </InplaceDisplay>
              <InplaceContent>
                <InputText
                  onChange={(e) => {
                    // replace any no number value
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    this.setState({ inputBillNumber: value }, () =>
                      this.checkChangePending("inputBillNumber", value)
                    );
                  }}
                  value={inputBillNumber}
                />
              </InplaceContent>
            </Inplace>
          </div>
          <PeppolView
            bill={this.props.selectedBill}
            toast={this.toast}
            intl={this.props.intl}
            parentUpdate={this.props.handleParentUpdate}
          ></PeppolView>
        </div>

        <div className="p-fluid col-12 mb-2 flex justify-content-between align-items-center">
          <div>
            <TranslatedCB
              value={inputBillStatus}
              options={BILL_STATUS}
              onChange={(selection) => {
                this.setState({ inputBillStatus: selection }, () =>
                  this.checkChangePending("inputBillStatus", selection)
                );
              }}
            />
          </div>

          <div className="flex align-items-center ml-2">
            <Checkbox
              inputId="cb_tt"
              className="mr-2"
              checked={inputTransportTicket}
              onChange={(e) => {
                this.setState({ inputTransportTicket: e.checked }, () =>
                  this.checkChangePending("inputTransportTicket", e.checked)
                );
              }}
            />
            <label htmlFor="cb_tt">
              {intl.formatMessage({ id: BILLS_TRANSPORT_TICKET_LABEL })}
            </label>
          </div>
        </div>

        <div
          className={
            notResponsive ? "p-field col-6 bill_edit_row" : "bill_edit_row_resp"
          }
        >
          <CustomerSelector
            value={inputCustomer}
            onChange={(selection) =>
              this.setState({ inputCustomer: selection }, () => {
                if (selection) {
                  const { phoneHome, gsm, address } = selection;
                  this.setState({
                    inputPhone: phoneHome ? phoneHome : "",
                    inputMobile: gsm ? gsm : "",
                    addressDisplay: address,
                  });
                  // Set address values for customer if set. Clear addresses if the selected customer has none.
                  if (selection.addressId || selection.addressIdInvoice) {
                    this.updateCustomerAddress({
                      addressId: selection.addressId,
                      invoiceAddressId: selection.addressIdInvoice,
                    });
                  } else {
                    this.setState({
                      inputDeparture: null,
                      legacyFromAddress: "",
                      legacyFromName: "",
                      inputBillingAddress: null,
                    });
                  }
                  if (selection.personId) {
                    this.props.handleCustomerSelection(
                      selection.personId,
                      selection.healthInsuranceNumber
                    );
                  }
                }
                this.checkChangePending("inputCustomer", selection);
              })
            }
          />
        </div>

        {!notResponsive ? this.renderCustomerInfobox() : <></>}

        <div
          className={
            notResponsive ? "p-field col-6 bill_edit_row" : "bill_edit_row_resp"
          }
        >
          <AddressSelector
            value={inputBillingAddress}
            onChange={(selection) =>
              this.setState({ inputBillingAddress: selection }, () =>
                this.checkChangePending("inputBillingAddress", selection)
              )
            }
            placeholder={intl.formatMessage({ id: ADDRESS_TYPE_INVOICE })}
            valid={validBillingAddress}
          />
        </div>

        {notResponsive ? this.renderInfoboxRow() : this.renderAddressInfobox()}

        <div
          className={`mb-2 ${
            notResponsive ? "p-field col-6 justify-content-end mt-2" : ""
          } flex flex-column align-items-start`}
        >
          <SplitDateTimeInput
            value={inputTransportDate}
            onChange={(e) => {
              let newTransportDate = valiDate(e) ? new Date(e) : null;
              this.setState({ inputTransportDate: newTransportDate }, () => {
                this.checkChangePending("inputTransportDate", newTransportDate);
              });
            }}
            label={intl.formatMessage({ id: BILLS_TRANSPORT_DATE_LABEL })}
          />
        </div>

        <div
          className={`flex align-items-end mb-2 ${
            notResponsive ? "col-6 mt-2" : "bill_edit_row_resp"
          }`}
        >
          <TranslatedCB
            value={inputTransportType}
            options={TRANSPORT_TYPES}
            onChange={(selection) => {
              this.setState({ inputTransportType: selection }, () => {
                this.checkChangePending("inputTransportType", selection);
              });
            }}
          />
        </div>

        <div
          className={
            notResponsive ? "p-field col-6 bill_edit_row" : "bill_edit_row_resp"
          }
        >
          <AddressSelector
            value={inputDeparture}
            onChange={(selection) => {
              if (selection) {
                this.setState(
                  {
                    inputDeparture: selection,
                    legacyFromAddress: selection
                      ? addressToString(selection)
                      : "",
                    legacyFromName: selection.searchkey
                      ? selection.searchkey
                      : selection.countryProvince,
                  },
                  () => {
                    this.checkChangePending("inputDeparture", selection);
                  }
                );
              } else {
                this.setState({
                  inputDeparture: null,
                  legacyFromAddress: "",
                  legacyFromName: "",
                });
              }
            }}
            placeholder={intl.formatMessage({ id: BILLS_DEPARTURE_LABEL })}
          />
        </div>

        {!notResponsive ? (
          <div className="bill_edit_row_resp flex flex-column">
            {this.renderAddressTextInput()}
          </div>
        ) : (
          <></>
        )}

        <div
          className={
            notResponsive ? "p-field col-6 bill_edit_row" : "bill_edit_row_resp"
          }
        >
          <AddressSelector
            value={inputDestination}
            onChange={(selection) =>
              this.setState(
                {
                  inputDestination: selection,
                  legacyToAddress: selection ? addressToString(selection) : "",
                  legacyToName: selection?.searchkey
                    ? selection.searchkey
                    : selection.countryProvince,
                },
                () => {
                  this.checkChangePending("inputDestination", selection);
                }
              )
            }
            placeholder={intl.formatMessage({
              id: BILLS_DESTINATION_LABEL,
            })}
          />
        </div>

        {notResponsive ? (
          <div className="p-field col-6 bill_edit_row flex flex-column">
            {this.renderAddressTextInput()}
          </div>
        ) : (
          <></>
        )}
        {
          <div
            className={`flex flex-column ${
              notResponsive
                ? "p-field col-6 bill_edit_row"
                : "bill_edit_row_resp"
            }`}
          >
            {this.renderAddressTextInput(false)}
          </div>
        }

        <div
          className={
            notResponsive ? "p-field col-6 bill_edit_row" : "bill_edit_row_resp"
          }
        >
          <span
            className="p-float-label comment_text_area"
            style={{ width: "100%" }}
          >
            <InputTextarea
              id="inputCommentInternal"
              value={inputCommentInternal}
              onChange={(e) =>
                this.setState({ inputCommentInternal: e.target.value }, () =>
                  this.checkChangePending(
                    "inputCommentInternal",
                    e.target.value
                  )
                )
              }
              rows={5}
              className="p-inputtext p-inputtextarea p-component"
              autoResize
            />
            <label htmlFor="inputCommentInternal">
              {intl.formatMessage({ id: BILLS_COMMENT_INTERNAL_LABEL })}
            </label>
          </span>
        </div>

        <div
          className={
            notResponsive ? "p-field col-6 bill_edit_row" : "bill_edit_row_resp"
          }
        >
          <span
            className="p-float-label comment_text_area"
            style={{ width: "100%" }}
          >
            <InputTextarea
              id={BILLS_COMMENT_EXTERNAL_LABEL}
              value={inputCommentExternal}
              onChange={(e) =>
                this.setState({ inputCommentExternal: e.target.value }, () =>
                  this.checkChangePending(
                    "inputCommentExternal",
                    e.target.value
                  )
                )
              }
              rows={5}
              className="p-inputtext p-inputtextarea p-component"
              autoResize
            />
            <label htmlFor={BILLS_COMMENT_EXTERNAL_LABEL}>
              {intl.formatMessage({ id: BILLS_COMMENT_EXTERNAL_LABEL })}
            </label>
          </span>
        </div>

        {/* NEW INPUTS ----------------- */}

        <div
          className={`${
            notResponsive ? "p-field col-12 justify-content-end mt-5" : "mt-4"
          } flex flex-column align-items-start `}
        >
          <span className="p-float-label" style={{ width: "100%" }}>
            <InputText
              value={inputHospitalOrderNumber}
              id={BILLS_HOSPITAL_ORDER_NUMBER_LABEL}
              style={{ width: "100% !important" }}
              onChange={(e) => {
                this.setState(
                  { inputHospitalOrderNumber: e.target.value },
                  () =>
                    this.checkChangePending(
                      "inputHospitalOrderNumber",
                      e.target.value
                    )
                );
              }}
            />
            <label htmlFor={BILLS_HOSPITAL_ORDER_NUMBER_LABEL}>
              {intl.formatMessage({ id: BILLS_HOSPITAL_ORDER_NUMBER_LABEL })}
            </label>
          </span>
        </div>

        <div
          className={`${
            notResponsive ? "p-field col-6 justify-content-end  mt-5" : " mt-4"
          } flex flex-column align-items-start `}
        >
          <span className="p-float-label" style={{ width: "100%" }}>
            <Dropdown
              showClear={true}
              inputId={BILLS_HOSPITAL_NB_ROUTES_LABEL}
              value={inputHospitalType}
              placeholder={
                inputHospitalType > 0
                  ? dropDownTrajet[inputHospitalType - 1].label
                  : ""
              }
              options={dropDownTrajet}
              onChange={(e) => {
                const value =
                  e.target.value !== undefined ? e.target.value : null;
                this.setState({ inputHospitalType: value }, () => {
                  this.checkChangePending("inputHospitalType", value);
                });
              }}
            />
            <label htmlFor={BILLS_HOSPITAL_NB_ROUTES_LABEL}>
              {intl.formatMessage({ id: BILLS_HOSPITAL_NB_ROUTES_LABEL })}
            </label>
          </span>
        </div>
        <div
          className={`${
            notResponsive ? "p-field col-6 justify-content-end " : ""
          } flex flex-column align-items-start `}
        >
          <span className="p-float-label" style={{ width: "100%" }}>
            <Dropdown
              showClear={true}
              value={inputHospitalPosition}
              placeholder={
                inputHospitalPosition > 0
                  ? dropDownPosition[inputHospitalPosition - 1].label
                  : ""
              }
              options={dropDownPosition}
              inputId={BILLS_HOSPITAL_POSITION_LABEL}
              onChange={(e) => {
                const value =
                  e.target.value !== undefined ? e.target.value : null;

                this.setState({ inputHospitalPosition: value }, () => {
                  this.checkChangePending("inputHospitalPosition", value);
                });
              }}
            />
            <label htmlFor={BILLS_HOSPITAL_POSITION_LABEL}>
              {intl.formatMessage({ id: BILLS_HOSPITAL_POSITION_LABEL })}
            </label>
          </span>
        </div>
        {/* ----------------- */}
        <div
          className={`${
            notResponsive ? "p-field col-6 justify-content-end " : ""
          } flex flex-column align-items-start `}
        >
          <SplitDateTimeInput
            value={inputOrderDate}
            onChange={(e) => {
              let newDate = valiDate(e) ? new Date(e) : null;
              this.setState({ inputOrderDate: newDate }, () => {
                this.checkChangePending("inputOrderDate", newDate);
              });
            }}
            label={intl.formatMessage({ id: BILLS_ORDER_DATE_LABEL })}
          />
        </div>

        <div
          className={`${
            notResponsive ? "p-field col-6 justify-content-end " : ""
          } flex flex-column align-items-start`}
        >
          <SplitDateTimeInput
            value={inputReminder1Date}
            onChange={(e) => {
              let newDate = valiDate(e) ? new Date(e) : null;
              this.setState({ inputReminder1Date: newDate }, () => {
                this.checkChangePending("inputReminder1Date", newDate);
              });
            }}
            label={intl.formatMessage({ id: BILLS_FIRST_REMINDER_DATE_LABEL })}
          />
        </div>

        <div
          className={`${
            notResponsive ? "p-field col-6 justify-content-end " : ""
          } flex flex-column align-items-start`}
        >
          <SplitDateTimeInput
            value={inputReminder2Date}
            onChange={(e) => {
              let newDate = valiDate(e) ? new Date(e) : null;
              this.setState({ inputReminder2Date: newDate }, () => {
                this.checkChangePending("inputReminder2Date", newDate);
              });
            }}
            label={intl.formatMessage({ id: BILLS_SECOND_REMINDER_DATE_LABEL })}
          />
        </div>

        <div
          className={`${
            notResponsive ? "p-field col-6 justify-content-end " : ""
          } flex flex-column align-items-start`}
        >
          <SplitDateTimeInput
            value={inputDASDate}
            onChange={(e) => {
              let newDate = valiDate(e) ? new Date(e) : null;
              this.setState({ inputDASDate: newDate }, () => {
                this.checkChangePending("inputDASDate", newDate);
              });
            }}
            label={intl.formatMessage({ id: BILLS_DAS_DATE_LABEL })}
          />
        </div>
        <div
          className={`${
            notResponsive ? "p-field col-12 justify-content-end " : ""
          } flex flex-column align-items-start`}
        >
          <SplitDateTimeInput
            value={inputReminderUpdate}
            onChange={(e) => {
              let newDate = valiDate(e) ? new Date(e) : null;
              this.setState({ inputReminderUpdate: newDate }, () => {
                this.checkChangePending("inputReminderUpdate", newDate);
              });
            }}
            label={intl.formatMessage({ id: BILLS_REMINDER_UPDATE_LABEL })}
          />
        </div>
      </div>
    );
  };

  renderPositionsTable = () => {
    const { inputPositions, inputTotalAmount } = this.state;
    let notResponsive = isDesktop || this.props.isLandscape;
    return (
      <div className={notResponsive ? "p-field col-12" : ""}>
        <Panel
          header={this.props.intl.formatMessage({
            id: MESSAGE_KEYS.BILLS_POSITIONS_TITLE,
          })}
          className={
            notResponsive ? "col-12" : "bill_position_panel_responsive"
          }
        >
          <BillPositionEditLayout
            value={inputPositions}
            handleDelete={this.handlePositionDelete}
            handleAdd={() => {
              this.setState({ positionDialogVisible: true });
            }}
            handleEdit={(positionIndex) => {
              this.setState({
                positionDialogVisible: true,
                positionIndex,
              });
            }}
            handleReorder={this.handleRowReorder}
            totalAmount={inputTotalAmount}
          />
        </Panel>
      </div>
    );
  };

  /**
   * Renders the bottom row of the form.
   * Returns a loading animation if a data transfer is pending, returns a row containing the save- & reset-button else.
   */
  renderButtonRow = () => {
    const {
      RESET_VALUES,
      BILLS_CREATE_BUTTON_LABEL,
      BILLS_SAVE_BUTTON_LABEL,
      ACTIVE,
      INACTIVE,
    } = MESSAGE_KEYS;
    const { intl, selectedBill } = this.props;
    const { savePending, inputActive } = this.state;

    let saveButtonLabel = "";
    if (selectedBill && selectedBill.transactionId < 0) {
      saveButtonLabel = intl.formatMessage({
        id: BILLS_CREATE_BUTTON_LABEL,
      });
    } else {
      saveButtonLabel = intl.formatMessage({
        id: BILLS_SAVE_BUTTON_LABEL,
      });
    }
    return (
      <div className="account_button_row">
        <Button
          onClick={async () => {
            await this.handleSaveClick();
          }}
          label={saveButtonLabel}
          disabled={savePending}
          icon={savePending ? "pi pi-spin pi-spinner" : ""}
        />

        <ToggleButton
          checked={inputActive ? true : false}
          offIcon="pi pi-times"
          offLabel={intl.formatMessage({ id: INACTIVE })}
          onIcon={"pi pi-check"}
          onLabel={intl.formatMessage({ id: ACTIVE })}
          onChange={(e) =>
            this.setState({ inputActive: e.value }, () => {
              this.checkChangePending("inputActive", e.value);
            })
          }
          disabled={savePending}
        />

        <Button
          onClick={() => this.initInputs()}
          label={intl.formatMessage({
            id: RESET_VALUES,
          })}
          className="p-button-warning"
          disabled={savePending}
        />
      </div>
    );
  };

  render = () => {
    if (this.props.selectedBill) {
      const {
        positionDialogVisible,
        positionIndex,
        inputPositions,
        positionPresets,
      } = this.state;
      return (
        <div>
          <Toast ref={(el) => (this.toast = el)} />
          <AddPositionDialog
            handleAdd={this.handleRowAdd}
            onHide={() =>
              this.setState({
                positionDialogVisible: false,
                positionIndex: null,
              })
            }
            visible={positionDialogVisible}
            value={
              positionIndex !== null
                ? inputPositions[positionIndex]
                : EMPTY_TRANSACTION
            }
            presets={positionPresets}
          />
          {this.renderEditInputs()}
          {this.renderPositionsTable()}
          {this.renderButtonRow()}
        </div>
      );
    } else {
      return <ProgressSpinner />;
    }
  };
}

const mapStateToProps = (state) => {
  try {
    const {
      authentication: {
        currentUser: { userRoles = [] },
      },
    } = state;
    return {
      userRoles,
    };
  } catch (mapException) {
    return { userRoles: [] };
  }
};

export default connect(mapStateToProps, { setChangePending })(
  injectIntl(withOrientationChange(BillEditLayout))
);
