/**
 * This component handles the bill export to peppol process.
 *
 * @version 1.0
 * @author [Dylan Ribeiro]
 */

//react
import React from "react";

//primeng
import "./Style.scss";
import { ProgressBar } from "primereact/progressbar";
import { Button } from "primereact/button";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

//utils
import { initLogger, sendInvoiceToPeppol } from "common/Helpers";
import { BACKGROUND_TASKS } from "assets/staticData/enums";
import { connect } from "react-redux";

class PeppolExporterTask extends React.Component {
  //ENUMS
  DELAY_SHOW_END_MESSAGE = 2000;
  DELAY_CLOSE_COMPONENT = 3000;

  logger = initLogger("peppol_exporter_view");

  constructor(props) {
    super(props);

    this.state = {
      isFetching: false,
      isFinished: false,
      peppolProceededBills: 0,
      totalPeppolBills: 0,
      activeInvoiceNumber: "",

      peppolInvoicesToProceed: [],
      failedSendInvoices: [],
      successSendInvoices: [],
    };
  }

  componentDidMount() {
    this.logger.log("Peppol Exporter Task started... " + new Date());
    this.handleProceedBillsToPeppol();
  }

  componentWillUnmount() {
    this.logger.log("Peppol Exporter Task ended. " + new Date());
  }

  handleProceedBillsToPeppol = async () => {
    let fastShowEnding = false;
    const { peppolInvoicesToProceed } = this.props;
    try {
      if (!peppolInvoicesToProceed) {
        throw new Error("No invoices to proceed.");
      }

      this.setState({ totalPeppolBills: peppolInvoicesToProceed?.length });

      for (const bill of peppolInvoicesToProceed) {
        await this.proceedPeppolBill(bill)
          .then(() => {
            /// append proceeded bills
            this.setState((prevState) => ({
              peppolProceededBills: prevState.peppolProceededBills + 1,
              successSendInvoices: [
                ...prevState.successSendInvoices,
                bill?.invoiceNumber,
              ],
            }));
          })
          .catch((ex) => {
            this.logger.warn(
              `Peppol invoice for transaction (${bill?.transactionId}) not created due to: ${ex}`
            );
            /// append failed bills
            this.setState((prevState) => ({
              peppolProceededBills: prevState.peppolProceededBills + 1,
              failedSendInvoices: [
                ...prevState.failedSendInvoices,
                bill?.invoiceNumber,
              ],
            }));
          });
      }
    } catch (ex) {
      this.logger.error(
        "Could not fetch peppol failed/warned invoices due to: " + ex
      );
      fastShowEnding = true;
    } finally {
      this.showSummary(fastShowEnding);
    }
  };

  proceedPeppolBill = async (bill) => {
    return new Promise(async (resolve, reject) => {
      try {
        this.setState({ activeInvoiceNumber: bill?.invoiceNumber });
        await sendInvoiceToPeppol(bill);
        resolve();
      } catch (ex) {
        reject(ex);
      }
    });
  };

  showSummary = (fastShowEnding) => {
    setTimeout(
      () => {
        this.setState({ isFinished: true });
      },
      fastShowEnding === false ? this.DELAY_SHOW_END_MESSAGE : 0
    );
  };

  killMyself = () => {
    this.props.killTask(BACKGROUND_TASKS.PeppolExporterTask);
  };

  generateSummaryTippy = (summary) => {
    // null or undefined or unknown
    if (summary == null) {
      return null;
    }

    return (
      <div>
        {summary.map((invoiceId, index) => (
          <div key={index}>{invoiceId}</div>
        ))}
      </div>
    );
  };

  render = () => {
    const {
      totalPeppolBills,
      peppolProceededBills,
      isFetching,
      activeInvoiceNumber,
      isFinished,
      failedSendInvoices,
      successSendInvoices,
    } = this.state;

    const progressPercentage = Math.ceil(
      (peppolProceededBills / totalPeppolBills) * 100
    );

    return (
      <div className="peppol-exporter-container">
        {isFetching && (
          <div className="loading">
            <img
              src="assets/layout/images/peppol-white-transparent.png"
              alt="peppol icon"
              className="peppol-icon"
              title="Peppol"
            />
            <i className="pi pi-spin pi-spinner loading-icon"></i>
          </div>
        )}

        {!isFetching && !isFinished && (
          <div className="processing">
            <div className="status">
              {peppolProceededBills} / {totalPeppolBills} ({activeInvoiceNumber}
              )
            </div>
            <div className="progress-bar">
              <ProgressBar
                value={progressPercentage}
                color="#03a8dd"
              ></ProgressBar>
            </div>
          </div>
        )}

        {isFinished && (
          <div className="finished">
            <div className="close-button-container">
              <Button
                icon="pi pi-times"
                className="p-button-rounded p-button-danger custom_delete_button"
                onClick={() => this.killMyself()}
              />
            </div>
            <div className="summary-container">
              <Tippy
                content={this.generateSummaryTippy(successSendInvoices)}
                disabled={successSendInvoices?.length === 0}
              >
                <span className="checked">
                  {successSendInvoices?.length}
                  <i className="pi pi-check-circle checked-icon"></i>
                </span>
              </Tippy>

              <Tippy
                content={this.generateSummaryTippy(failedSendInvoices)}
                disabled={failedSendInvoices?.length === 0}
              >
                <span className="failed">
                  {failedSendInvoices?.length}
                  <i className="pi pi-times-circle failed-icon"></i>
                </span>
              </Tippy>
            </div>
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    peppolInvoicesToProceed: state.application?.peppolInvoicesToProceed,
  };
};

export default connect(mapStateToProps, {})(PeppolExporterTask);
