export const RESPONSIVE_BREAKPOINT = 1700;

/**
 * Contains router paths to the respective views
 */
export const URLS = Object.freeze({
  ACCOUNT: "/account",
  USERS: "/users",
  CUSTOMERS: "/customers",
  SCHEDULER: "/scheduler",
  PLACEHOLDER: "/empty",
  ADDRESSES: "/addresses",
  BILLS: "/bills",
  PAYMENTS: "/payments",
  VACATIONS: "/vacations",
  SHIFTS: "/shifts",
  SETTINGS: "/configuration",
  PRODUCTS: "/products",
  VEHICLES: "/vehicles",
  ON_CALL: "/oncall",
  NOT_FOUND: "/404",
  DASHBOARD: "/",
  DRIVER: "/drivers",
  REGULARS: "/regulars",
  REGULARS_LIST: "/regulars_list",
  HOLIDAYS: "/holidays",
  DRIVES: "/drives",
  DRIVER_STATUS: "/driver_status",
  APPOINTMENTS_SEARCH: "/appointments_search",
  SHOUTBOX: "/shoutbox",
  MONTH_REPORT: "/month_report",
});
/**
 * Contains backend URLs for the respective queries.
 */
export const QUERIES = Object.freeze({
  AUTHENTICATION: "api/base/auth/signin",
  GET_USERS_PAGES: "api/base/user/findAll",
  GET_USER_BY_ID: "api/base/user/getDTOById/",
  EDIT_USER: "api/base/user/editDTO",
  CREATE_USER: "api/base/user/new",
  FETCH_ROLES: "api/base/role/noPaging",
  FETCH_TRANSPORT_TYPES: "api/base/typeController/transportTypes",
  FETCH_MAIN_LOCATIONS: "api/base/typeController/appointmentStationTypes",
  FETCH_ADDRESS_TYPES: "api/base/typeController/addressTypes",
  FETCH_SEXES: "api/base/typeController/sexTypes",
  FETCH_TITLES: "api/base/typeController/titleTypes",
  FETCH_VEHICLE_TYPE: "api/base/typeController/vehicleTypes",
  GET_CUSTOMERS_PAGES: "api/base/customer/fulltext/findAllSimple",
  GET_CUSTOMER_BY_ID: "api/base/customer/getDTOById/",
  GET_CUSTOMER_FILTERED: "api/base/customer/findByClientSearchQuery?",
  EDIT_CUSTOMER: "api/base/customer/editDTO",
  CREATE_CUSTOMER: "api/base/customer/new",
  GET_ADDRESS_BY_ID: "api/base/address/getDTOById/",
  GET_ADDRESSES_PAGES: "api/base/address/findByAddressSearchQuery?",
  EDIT_ADDRESS: "api/base/address/editDTO",
  CREATE_ADDRESS: "api/base/customer/new",
  FETCH_ADDRESSES: "api/base/address/findAll",
  GET_BILL_BY_ID: "api/base/transaction/getDTOById/",
  GET_BILLS_PAGES: "api/base/transaction/findByTransactionSearchQuery?",

  GET_BILL_CUSTOMER_REPORT: "api/base/report/printCustomer?id=",
  GET_BILL_REPORT: "api/base/report/transactionById?transactionId=",
  GET_WARNING_REPORT: "api/base/report/transactionByIdSetState?transactionId=",
  SEND_BILL_REPORT: "api/base/mail/sendMailForTransactions?",

  GET_FILE: "api/base/file/loadFile?",

  GENERATE_REMINDER_FOR_FILTERED:
    "api/base/transaction/setAndGenerateReminder?",

  EDIT_BILL: "api/base/transaction/editDTO",

  UPLOAD_CUSTOMER_FILE: "api/base/file/uploadForCustomer?folder=",

  UPLOAD_BILL_FILE: "api/base/file/uploadForTransaction?folder=",
  REMOVE_BILL_FILE: "api/base/file/removeFilesById?fileArchiveId=",

  GET_PAYMENT_BY_ID: "api/base/payment/getDTOById/",
  GET_PAYMENTS_PAGES: "api/base/payment/findByPaymentSearchQuery?",
  GET_PAYMENTS_BY_BILL: "api/base/payment/getPaymentsByTransactionId/",
  EDIT_PAYMENT: "api/base/payment/editDTO",

  GET_PAYMENT_PRESETS: "api/base/product/findAll?size=3000&sort=productId,desc",
  GET_PAYMENT_PRESET_BY_ID: "api/base/product/getById/",
  EDIT_PAYMENT_PRESET: "api/base/product/edit",

  CREATE_MULTI_PAYMENT: "api/base/payment/multiplePayment",
  GET_MULTI_BILL_EXPORT: "api/base/transaction/setAndGenerateExport",
  /*   GET_MULTI_BILL_EXPORT: "api/base/transaction/setAndGenerateExport?isHospital=true&", */
  GET_MULTI_BILLS_PDF: "api/base/report/transactionById?transactionId=",
  GET_BILL_EXPORT: "api/base/report/transactionExportById?transactionId=",
  /*   GET_BILL_EXPORT: "api/base/report/transactionExportById?isHospital=true&transactionId=", */
  GET_FILTERED_BILL_EXPORT: "api/base/transaction/setAndGenerateExport?",
  /*   GET_FILTERED_BILL_EXPORT: "api/base/transaction/setAndGenerateExport?isHospital=true&", */
  UPDATE_MULTI_BILL_STATUS: "api/base/transaction/editState",

  GET_USER_REPORT: "api/base/report/printUser?id=",

  GET_SALES_BY_TYPE_AND_PERIOD:
    "api/base/dashboard/getDashboardInvoicePerYearAndMonth?types=",

  GET_DRIVERS: "api/base/user/findSimpleDriverList",
  GET_USERS: "api/base/user/findSimpleUserList",

  GET_CARS: "api/base/vehicle/findAll?size=3000&sort=vehicleId,desc",
  GET_CAR_BY_ID: "api/base/vehicle/getById/",
  EDIT_CAR: "api/base/vehicle/edit",

  GET_APPOINTMENT_BY_ID: "api/base/transportAppointment/getDTOById/",
  GET_CHECKLIST_BY_ID: "api/base/checklist/getById/",
  GET_DETAILED_APPOINTMENTS_BY_DATE:
    "api/base/transportAppointment/dateBetween",
  EDIT_APPOINTMENT: "api/base/transportAppointment/editDTO",

  CANCEL_APPOINTMENTS: "api/base/transportAppointment/cancelAppointments",

  GET_APPOINTMENT_STATUS: "api/base/transportAppointment/getStates",
  DELETE_APPOINTMENT: "api/base/transportAppointment/deleteDTOById/",
  GET_EMPLOYEE_ON_CALLS_RANGE:
    "api/base/transportAppointment/getEmployeeLayerList?fromDate=",

  GET_SETTINGS: "api/base/config/findLux",
  EDIT_SETTINGS: "api/base/config/edit",

  GET_ON_CALL_TYPES: "api/base/appointment_types/findStandby",
  GET_ON_CALL_BY_ID: "api/base/standbyAppointment/getById/",
  GET_ON_CALLS_BY_DATE: "api/base/standbyAppointment/findByDateRange?fromDate=",
  EDIT_ON_CALL: "api/base/standbyAppointment/editDTO",
  DELETE_ON_CALL: "api/base/standbyAppointment/deleteDTOById/",

  GET_SHIFT_TYPES: "api/base/appointment_types/findLayer",
  GET_SHIFT_BY_ID: "api/base/layerAppointment/getById/",
  GET_SHIFTS_BY_DATE: "api/base/layerAppointment/findByDateRange?fromDate=",
  EDIT_SHIFT: "api/base/layerAppointment/editDTO",
  DELETE_SHIFT: "api/base/layerAppointment/deleteDTOById/",

  GET_VACATION_TYPES: "api/base/vacationAppointment/getStates",
  GET_VACATION_BY_ID: "api/base/vacationAppointment/getById/",
  GET_VACATIONS_BY_DATE:
    "api/base/vacationAppointment/findByDateRange?fromDate=",
  GET_VACATION_REPORT_BY_DATE: "api/base/report/vacationBudget?fromDate=",
  GET_VACATIONS_BY_DATE_AND_USER:
    "api/base/vacationAppointment/findByDateRangeAndUser?fromDate=",
  GET_VACATION_REPORT_BY_DATE_AND_USER:
    "api/base/user/getVacationBudget?fromDate=",
  EDIT_VACATION: "api/base/vacationAppointment/editDTO",
  DELETE_VACATION: "api/base/vacationAppointment/deleteDTOById/",
  UPDATE_OLD_VACATIONS:
    "api/base/vacationAppointment/setPastApprovedToTaken?fromDate=",

  EDIT_VACATION_BUDGET: "api/base/user/editVacationBudget",
  DELETE_VACATION_BUDGET: "api/base/user/deleteVacationBudget/",

  ADD_USERS_EMERGENCY_PERSON: "api/base/user/addEmergencyPerson",
  DELETE_USERS_EMERGENCY_PERSON: "api/base/user/deleteEmergencyPerson",

  EDIT_CUSTOMER_PHONE: "api/base/customer/editCustomerPhone",

  GET_CUSTOMER_PAYMENT_INFO: "api/base/customer/getPaymentInfo/",

  GET_REGULARS: "api/base/customer/getRegularCustomers",
  EDIT_REGULARS: "api/base/customer/editRegularCustomer",
  DELETE_REGULARS: "api/base/customer/deleteRegularCustomer/",

  GET_HOLIDAYS_BY_DATE: "api/base/bankHoliday/findByDateRange",
  GET_HOLIDAY: "api/base/bankHoliday/getDtoById",
  EDIT_HOLIDAY: "api/base/bankHoliday/editDTO",
  DELETE_HOLIDAY: "api/base/bankHoliday/deleteDTOById/",

  GET_APPOINTMENTS_BY_DRIVER: "api/base/transportAppointment/forDriver/",
  GET_DRIVE_APPOINTMENTS:
    "api/base/transportAppointment/getDTOListForDriver?personId=",
  GET_DRIVE_APPOINTMENTS_UNFILTERED:
    "api/base/transportAppointment/getUnfilteredDTOListForDriver?personId=",
  GET_DRIVE_REPORT: "api/base/report/dayReportDriver?personId=",
  GET_DELIVERY_NOTE: "api/base/report/deliveryNote",
  GET_DAILY_DRIVE: "api/base/daily_driver/findByFilter?personId=",
  EDIT_DAILY_DRIVE: "api/base/daily_driver/editDTO",
  DELETE_DAILY_DRIVE: "api/base/daily_driver/delete/",
  SET_APPOINTMENT_READ: "api/base/transportAppointment/driverRead?hasRead=",
  GET_MONTHLY_REPORT: "api/base/report/monthlyReportDriver?",

  GET_DAILY_DRIVER_STATUS: "api/base/daily_driver/getDriverStates?date=",
  SET_DAILY_DRIVER_STATUS: "api/base/daily_driver/createState",

  GET_HISTORY_BY_APPOINTMENT: "api/base/history/getByTypeId/appointment",

  GET_MONTH_REPORT: "api/base/monthly_report/calcByFilter?reportDate=",

  PEPPOL_LOGIN: "https://beinvoice.lu/api/login",

  ADD_PEPPOL_TRANSACTION_LOG:
    "api/base/transactionPeppolLog/addPeppolTransactionLog",

  UPDATE_PEPPOL_STATUS: "api/base/transaction/updatePeppolStatus",

  GET_PEPPOL_BILLS_TO_SEND: "api/base/transaction/findAllPeppolInvoicesToSend",
  GET_PEPPOL_ADDRESSES: "api/base/address/findPeppolAddresses",
  SET_PEPPOL_DOCUMENT: "https://beinvoice.lu/api/document/json",
  EXPORT_PEPPOL_ALL: "api/base/transaction/findPeppolInvoices",
});

export const PEPPOL_STATES = Object.freeze({
  FAILED: "failed",
  WARNING: "warning",
  SUCCESS: "success",
});

export const PEPPOL_MESSAGES = Object.freeze({
  RESEND_BILL: "Requested a Peppol resend",
});

export const PEPPOL_REQUEST_RESPONDS = Object.freeze({
  SUCCESS: "Success",
});

export const PEPPOL_RESEND_CODE = 381;

export const BACKGROUND_TASKS = Object.freeze({
  PEPPOL_EXPORT: "peppol_export",
});

/**
 * Contains severity levels for growl messages.
 */
export const MESSAGE_SEVERITY = Object.freeze({
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warn",
  ERROR: "error",
});
/**
 * Contains the values used to control application localization.
 */
export const LOCALES = Object.freeze({
  GERMAN: { languageId: 1, key: "DE" },
  FRENCH: { languageId: 2, key: "FR" },
});
/**
 * Contains the station names for the appointment planner component.
 */
export const STATION_NAMES = Object.freeze({
  LUX: "Luxembourg",
  ETB: "Ettelbrück",
  WLZ: "Wiltz",
});
/**
 * Contains http error code for server requests.
 */
export const NETWORK_ERROR_CODES = Object.freeze({
  CLIENT_ERROR: 400,
  UNAUTHORIZED_ERROR: 401,
  DUPLICATE_ERROR: 409,
  SERVER_ERROR: 500,
  NO_SERVER_ERROR: 501,
  EXCEPTION_ERROR: 418,
});
/**
 * Contains the localization message keys for the frontend labels.
 */
export const MESSAGE_KEYS = Object.freeze({
  MENU_APPOINTMENTS: "menu.appointments",
  MENU_ACCOUNT: "menu.account",
  MENU_USERS: "menu.users",
  MENU_CUSTOMERS: "menu.customers",
  MENU_LOGOUT: "menu.logout",
  MENU_ADDRESSES: "menu.addresses",
  MENU_BILLS: "menu.bills",
  MENU_PAYMENTS: "menu.payments",
  MENU_BILL_MANAGEMENT: "menu.bill.management",
  MENU_COMPANY_MANAGEMENT: "menu.company.management",
  MENU_APPOINTMENT_MANAGEMENT: "menu.appointment.management",
  MENU_VACATION: "menu.vacation",
  MENU_SHIFTS: "menu.shifts",
  MENU_ON_CALL: "menu.on.call",
  MENU_SETTINGS: "menu.settings",
  MENU_DASHBOARD: "menu.dashboard",
  MENU_PRODUCTS: "menu.products",
  MENU_VEHICLES: "menu.vehicles",
  MENU_EMPTY: "menu.empty",
  MENU_REGULARS: "menu.regulars",
  MENU_HOLIDAYS: "menu.holidays",
  MENU_DRIVES: "menu.drives",
  MENU_DRIVER_STATUS: "menu.driver.status",
  MENU_APPOINTMENTS_SEARCH: "menu.appointments.search",
  MENU_SHOUTBOX: "menu.shoutbox",
  MENU_MONTH_REPORT: "menu.month.report",

  LOGIN_USERNAME_LABEL: "login.username.label",
  LOGIN_PASSWORD_LABEL: "login.password.label",
  LOGIN_BUTTON_LABEL: "login.button.label",
  LOGIN_TITLE_LABEL: "login.title.label",

  LOGIN_WARNING_FAILED: "login.warning.failed",

  DASHBOARD_SALES_TITLE: "dashboard.sales.title",
  DASHBOARD_BIRTHDAY_TITLE: "dashboard.birthday.title",
  DASHBOARD_OVERVIEW_TABLE_YEAR_HEADER: "dashboard.overview.table.year.header",
  DASHBOARD_OVERVIEW_TABLE_MONTH_HEADER:
    "dashboard.overview.table.month.header",
  DASHBOARD_SALES_FILTER_TITLE: "dashboard.sales.filter.title",
  DASHBOARD_SALES_FILTER_FROM_LABEL: "dashboard.sales.filter.from.label",
  DASHBOARD_SALES_FILTER_TO_LABEL: "dashboard.sales.filter.to.label",
  DASHBOARD_SALES_FILTER_INCLUDE_LABEL: "dashboard.sales.filter.include.label",

  EMPTY_PAGE_TITLE: "empty.page.title",
  EMPTY_PAGE_SUMMARY: "empty.page.summary",

  ACCOUNT_TITLE_LABEL: "account.title.label",
  ACCOUNT_EMAIL_LABEL: "account.email.label",
  ACCOUNT_NAME_LABEL: "account.name.label",
  ACCOUNT_ROLES_LABEL: "account.roles.label",
  ACCOUNT_LANGUAGE_LABEL: "account.language.label",
  ACCOUNT_PASSWORD_LABEL: "account.password.label",
  ACCOUNT_PASSWORD_REPEAT_LABEL: "account.password.repeat.label",
  ACCOUNT_SAVE_BUTTON_LABEL: "account.save.button.label",
  ACCOUNT_CREATE_BUTTON_LABEL: "account.create.button.label",

  ACCOUNT_FIRST_LOGIN_HEADER: "account.first.login.header",
  ACCOUNT_FIRST_LOGIN_WARNING: "account.first.login.warning",
  ACCOUNT_SAVE_PASSWORD_LABEL: "account.save.password.label",

  ACCOUNT_ERROR_NAME_LENGTH: "account.error.name.length",
  ACCOUNT_ERROR_NAME_REQUIRED: "account.error.name.required",
  ACCOUNT_ERROR_EMAIL_INVALID: "account.error.email.invalid",
  ACCOUNT_ERROR_EMAIL_REQUIRED: "account.error.email.required",
  ACCOUNT_ERROR_PASSWORD_MATCH: "account.error.password.match",
  ACCOUNT_ERROR_PASSWORD_LENGTH: "account.error.password.length",
  ACCOUNT_ERROR_COULD_NOT_LOAD: "account.error.could.not.load",

  ACCOUNT_SAVE_SUCCESS_MESSAGE: "account.save.success.message",

  USERS_TITLE_LABEL: "users.title.label",
  USERS_SELECT_USER_LABEL: "users.select.user.label",
  USERS_FIRST_NAME_LABEL: "users.first.name.label",
  USERS_LAST_NAME_LABEL: "users.last.name.label",
  USERS_NICK_NAME_LABEL: "users.nick.name.label",
  USERS_HOME_PHONE_LABEL: "users.home.phone.label",
  USERS_WORK_PHONE_LABEL: "users.work.phone.label",
  USERS_MOBILE_PHONE_LABEL: "users.mobile.phone.label",
  USERS_HIRE_DATE_LABEL: "users.hire.date.label",
  USERS_ALLERGIES_LABEL: "users.allergies.label",
  USERS_DISEASES_LABEL: "users.diseases.label",
  USERS_BLOODTYPE_LABEL: "users.bloodtype.label",
  USERS_TIMERECORDING_LABEL: "users.timerecording.label",
  USERS_WEEKHOURS_LABEL: "users.weekhours.label",

  USERS_VACATION_BUDGET_START_LABEL: "users.vacation.budget.start.label",
  USERS_VACATION_BUDGET_YEAR_LABEL: "users.vacation.budget.year.label",
  USERS_VACATION_BUDGET_LABEL: "users.vacation.budget.label",
  USERS_VACATION_BUDGET_COMMENT_LABEL: "users.vacation.budget.comment.label",
  USERS_VACATION_BUDGET_SAVE_SUCCESS: "users.vacation.budget.save.success",
  USERS_BUDGET_DELETE_TITLE: "users.budget.delete.title",
  USERS_BUDGET_DELETE_MESSAGE: "users.budget.delete.message",
  USERS_BUDGET_DELETE_FAIL: "users.budget.delete.fail",
  USERS_BUDGET_DELETE_SUCCESS: "users.budget.delete.success",

  USERS_VACATION_OVER_BUDGET_WARNING: "users.vacation.over.budget.warning",
  USERS_VACATION_OVER_BUDGET_ADMIN_WARNING:
    "users.vacation.over.budget.admin.warning",

  USERS_VACATION_BUDGET_UPDATE_BUTTON_LABEL:
    "users.vacation.budget.update.button.label",
  USERS_VACATION_BUDGET_CREATE_BUTTON_LABEL:
    "users.vacation.budget.create.button.label",

  USERS_ADD_EMERGENCY_PERSON_LABEL: "users.add.emergency.person.label",
  USERS_DELETE_EMERGENCY_PERSON_LABEL: "users.delete.emergency.person.label",
  USERS_EDIT_EMERGENCY_PERSON_LABEL: "users.delete.emergency.person.label",
  USERS_DELETE_EMERGENCY_PERSON_MESSAGE:
    "users.delete.emergency.person.message",

  USERS_EMERGENCY_PERSON_ADD_SUCCESS: "users.add.emergency.person.success",
  USERS_EMERGENCY_PERSON_ADD_FAIL: "users.add.emergency.person.fail",
  USERS_EMERGENCY_PERSON_EDIT_FAIL: "users.edit.emergency.person.fail",
  USERS_EMERGENCY_PERSON_EDIT_SUCCESS: "users.edit.emergency.person.success",
  USERS_EMERGENCY_PERSON_DELETE_FAIL: "users.delete.emergency.person.fail",
  USERS_EMERGENCY_PERSON_DELETE_SUCCESS:
    "users.delete.emergency.person.success",

  USERS_INFO_REPORT_BUTTON_LABEL: "users.info.report.button.label",

  CUSTOMER_DATA_TITLE_LABEL: "customer.data.title.label",
  CUSTOMER_BILL_TITLE_LABEL: "customer.bill.title.label",
  CUSTOMER_PAYMENT_TITLE_LABEL: "customer.payment.title.label",
  CUSTOMER_PAYMENT_TITLE_SELECTION_LABEL: "customer.payment.selection.label",

  CUSTOMERS_FILTER_TITLE_LABEL: "customers.filter.title.label",
  CUSTOMERS_FILTER_NAME: "customers.filter.name",
  CUSTOMERS_FILTER_ADDRESS: "customers.filter.address",
  CUSTOMERS_FILTER_PHONE: "customers.filter.phone",
  CUSTOMERS_FILTER_EMAIL: "customers.filter.email",
  CUSTOMERS_FILTER_INSURANCE_NUMBER: "appointment.healthInsuranceNumber",
  CUSTOMERS_FILTER_ONLY_ACTIVE: "customers.filter.only.active",

  CUSTOMER_FILTER_FUZZY_TOOLTIP: "customer.filter.fuzzy.tooltip",

  CUSTOMER_SELECTED_CUSTOMER_LABEL: "customer.selected.customer.label",

  CUSTOMERS_FIRST_NAME_LABEL: "appointment.firstname",
  CUSTOMERS_MIDDLE_NAME_LABEL: "appointment.girlName",
  CUSTOMERS_LAST_NAME_LABEL: "appointment.lastname",
  CUSTOMERS_EMAIL_LABEL: "appointment.email",
  CUSTOMERS_MATRICULATION_LABEL: "customers.matriculation.label",
  CUSTOMERS_PHONE_LABEL: "appointment.phoneHome.label",
  CUSTOMERS_MOBILE_LABEL: "customers.mobile.label",
  CUSTOMERS_FAX_LABEL: "customers.fax.label",
  CUSTOMERS_COMMENT_LABEL: "appointment.remark.label",
  CUSTOMERS_GENDER_LABEL: "appointment.sexId",
  CUSTOMERS_TITLE_LABEL: "appointment.titleId",
  CUSTOMERS_BIRTH_DATE_LABEL: "customers.birth.date.label",

  CUSTOMERS_ADDRESS_TITLE_LABEL: "customers.address.title.label",
  CUSTOMERS_ADD_ADDRESS_TITLE_LABEL: "customers.add.address.title.label",
  CUSTOMERS_ADD_PAYMENT_TITLE_LABEL: "customers.add.payment.title.label",
  CUSTOMERS_ADDRESS_TYPE_LABEL: "customers.address.type.label",
  CUSTOMERS_ADDRESS_LINE_1_LABEL: "appointment.line1.label",
  CUSTOMERS_ADDRESS_LINE_2_LABEL: "appointment.line2.label",
  CUSTOMERS_ADDRESS_ZIP_LABEL: "appointment.zipCode.label",
  CUSTOMERS_ADDRESS_CITY_LABEL: "appointment.countryProvince.label",
  CUSTOMERS_ADDRESS_RETRO_LABEL: "customers.address.retro.label",
  CUSTOMERS_ADDRESS_CREATE_BUTTON_LABEL:
    "customers.address.create.button.label",

  CUSTOMERS_ADDRESS_DUPLICATE_WARNING: "customers.address.duplicate.warning",

  CUSTOMERS_DEAD_LABEL: "customers.dead.label",

  CUSTOMERS_ERROR_MATRICULATION_REQUIRED:
    "customers.error.matriculation.required",
  CUSTOMERS_ERROR_MATRICULATION_LENGTH: "customers.error.matriculation.length",
  CUSTOMERS_VALIDATION_ERROR_INSURANCE_NUMBER:
    "customers.validation.error.insurance.number",

  CUSTOMERS_SAVE_SUCCESS_MESSAGE: "customers.save.success.message",

  CUSTOMERS_PRINT_BUTTON_LABEL: "customers.print.button.label",
  CUSTOMERS_SAVE_BUTTON_LABEL: "customers.save.button.label",
  CUSTOMERS_CREATE_BUTTON_LABEL: "customers.create.button.label",

  ADDRESSES_FILTER_TITLE_LABEL: "addresses.filter.title.label",
  ADDRESSES_FILTER_CUSTOMER_LABEL: "addresses.filter.customer.label",
  ADDRESSES_FILTER_ADDRESS_LABEL: "addresses.filter.address.label",
  ADDRESSES_FILTER_HIN_LABEL: "addresses.filter.hin.label",
  ADDRESSES_FILTER_SEARCHKEY_LABEL: "addresses.filter.searchkey.label",

  ADDRESSES_TITLE_LABEL: "addresses_title_label",
  ADDRESSES_SELECT_ADDRESS_LABEL: "addresses_select_address_label",
  ADDRESSES_SAVE_BUTTON_LABEL: "addresses_save_button_label",

  ADDRESSES_PHONE_1_LABEL: "addresses.phone.1.label",
  ADDRESSES_PHONE_2_LABEL: "addresses.phone.2.label",

  ADDRESSES_HOSPITAL_LABEL: "addresses.hospital.label",
  ADDRESSES_NURSING_LABEL: "addresses.nursing.label",
  ADDRESSES_AMBULANCE_LABEL: "addresses.ambulance.label",

  ADDRESSES_ERROR_LINE1_REQUIRED: "addresses.error.line1.required",
  ADDRESSES_ERROR_ZIP_REQUIRED: "addresses.error.zip.required",
  ADDRESSES_ERROR_CITY_REQUIRED: "addresses.error.city.required",

  APPOINTMENTS_TITLE_LABEL: "appointments.title.label",
  APPOINTMENTS_CREATE_DIALOG_TITLE_LABEL:
    "appointments.create.dialog.title.label",
  APPOINTMENTS_EDIT_DIALOG_TITLE_LABEL: "appointments.edit.dialog.title.label",
  APPOINTMENTS_CLIENT_LABEL: "appointment_client_label",
  APPOINTMENTS_START_ADDRESS_LABEL: "appointments.start.address.label",
  APPOINTMENTS_END_ADDRESS_LABEL: "appointments.end.address.label",
  APPOINTMENTS_APPOINTMENT_LABEL: "appointments.appointment.label",
  APPOINTMENTS_SECOND_APPOINTMENT_LABEL:
    "appointments.second.appointment.label",
  APPOINTMENTS_FIRST_DRIVER_LABEL: "appointments.first.driver.label",
  APPOINTMENTS_SECOND_DRIVER_LABEL: "appointments.second.driver.label",
  APPOINTMENTS_STATUS_LABEL: "appointments.status.label",
  APPOINTMENTS_VEHICLE_LABEL: "appointments.vehicle.label",
  APPOINTMENTS_LOCATION_LABEL: "appointments.location.label",
  APPOINTMENTS_NEW_EVENT_LABEL: "appointments.new.event.label",
  APPOINTMENTS_START_DATE_LABEL: "appointments.start.date.label",
  APPOINTMENTS_VEHICLE_TYPE_LABEL: "appointments.vehicle-type.label",

  APPOINTMENTS_LAST_PAYMENT_DATE_LABEL: "appointments.last.payment.date.label",
  APPOINTMENTS_LAST_BILL_NUMBER_LABEL: "appointments.last.bill.number.label",

  APPOINTMENTS_PASSENGER_STEP_LABEL: "appointments.passenger.step.label",
  APPOINTMENTS_DRIVE_STEP_LABEL: "appointments.drive.step.label",
  APPOINTMENTS_CONFIRM_STEP_LABEL: "appointments.confirm.step.label",
  APPOINTMENTS_MULTI_STEP_LABEL: "appointments.multi.step.label",

  APPOINTMENTS_CUSTOMER_HEADER: "appointments.customer.header",
  APPOINTMENTS_DEPARTURE_HEADER: "appointments.departure.header",
  APPOINTMENTS_DESTINATION_HEADER: "appointments.destination.header",
  APPOINTMENTS_RETURN_HEADER: "appointments.return.header",
  APPOINTMENTS_NEXT_HEADER: "appointment.nextAppointment.header",

  APPOINTMENTS_STATE_LABEL: "appointment.stateName.label",

  APPOINTMENTS_DRIVE_APPOINTMENT: "appointment.starttime",
  APPOINTMENTS_DEPARTURE_TIME: "appointment.meetingTime",
  APPOINTMENTS_RETURN_TIME: "appointment.endtime",
  APPOINTMENTS_KILOMETERS: "appointment.kilometer",
  APPOINTMENTS_KILOMETERS_BACK: "appointment.kilometerReturn",

  APPOINTMENTS_OUTBOUND_DRIVER_LABEL: "appointment.firstDriver.alias.label",
  APPOINTMENTS_OUTBOUND_SECOND_DRIVER_LABEL:
    "appointment.secondDriver.alias.label",
  APPOINTMENTS_RETURN_DRIVER_LABEL: "appointment.firstDriverReturn.alias.label",
  APPOINTMENTS_RETURN_SECOND_DRIVER_LABEL:
    "appointment.secondDriverReturn.alias.label",
  APPOINTMENTS_OUTBOUND_CAR_LABEL: "appointment.car.license_plate.label",
  APPOINTMENTS_RETURN_CAR_LABEL: "appointment.carIdReturn.license_plate.label",

  APPOINTMENTS_DEPART_ROOM_LABEL: "appointment.roomFrm.label",
  APPOINTMENTS_DEPART_DEPARTMENT_LABEL: "appointment.departmentFrm.label",
  APPOINTMENTS_DEPART_ADDRESS_COMMENT_LABEL: "appointment.fromAddressTxt.label",
  APPOINTMENTS_DEPART_ADDRESS_1_LABEL: "appointment.departureAddress1.label",
  APPOINTMENTS_DEPART_ADDRESS_2_LABEL: "appointment.departureAddress2.label",
  APPOINTMENTS_DEPART_ZIP_LABEL: "appointment.departureZipcode.label",
  APPOINTMENTS_FROM_LOCATION: "appointment.fromAddressKeyword",
  APPOINTMENTS_CUSTOMER_GSM: "appointment.gsm",
  APPOINTMENTS_CUSTOMER_FULL_NAME: "appointment.name",

  APPOINTMENTS_ARRIVAL_ROOM_LABEL: "appointment.roomTo",
  APPOINTMENTS_ARRIVAL_DEPARTMENT_LABEL: "appointment.departmentTo",
  APPOINTMENTS_ARRIVAL_ADDRESS_COMMENT_LABEL: "appointment.toAddressTxt.label",
  APPOINTMENTS_ARRIVAL_ADDRESS_1_LABEL: "appointment.arrivalAddress1.label",
  APPOINTMENTS_ARRIVAL_ADDRESS_2_LABEL: "appointment.arrivalAddress2.label",
  APPOINTMENTS_ARRIVAL_ZIP_LABEL: "appointment.arrivalZipcode.label",

  APPOINTMENTS_OUTWARD_PAPER_LABEL: "appointment.outwardPaper.label",
  APPOINTMENTS_OUTWARD_DEPARTURE_LABEL: "appointment.outwardDeparture.label",
  APPOINTMENTS_OUTWARD_ARRIVAL_LABEL: "appointment.outwardArrival.label",
  APPOINTMENTS_OUTWARD_DISCHARGE_LABEL: "appointment.outwardDischarge.label",

  APPOINTMENTS_RETURN_PAPER_LABEL: "appointment.returnPaper.label",
  APPOINTMENTS_RETURN_DEPARTURE_LABEL: "appointment.returnDeparture.label",
  APPOINTMENTS_RETURN_ARRIVAL_LABEL: "appointment.returnArrival.label",
  APPOINTMENTS_RETURN_DISCHARGE_LABEL: "appointment.returnDischarge.label",

  APPOINTMENTS_WEIGHT_NUMBER: "appointment.weight",
  APPOINTMENTS_STAIRS_COUNT: "appointment.stairsCount",
  APPOINTMENTS_VACCINATED: "appointment.patientVaccinated",
  APPOINTMENTS_TRANSPORT_TYPE_INVOICE: "appointment.transportTypeInvoice",

  APPOINTMENTS_BUS_LABEL: "appointments.bus.label",
  APPOINTMENTS_PATIENT_STAY_LABEL: "appointments.patient.stay.label",
  APPOINTMENTS_VACCINATED_LABEL: "appointments.vaccinated.label",
  APPOINTMENTS_CLEAR_ADDRESS: "appointments.clear.address",
  APPOINTMENTS_USE_CUSTOMER_ADDRESS: "appointments.use.customer.address",
  APPOINTMENTS_ROOM_LABEL: "appointments.room.label",
  APPOINTMENTS_DEPARTMENT_LABEL: "appointments.department.label",
  APPOINTMENTS_FREE_TEXT_LABEL: "appointments.free.text.label",
  APPOINTMENTS_KEY_WORD_LABEL: "appointments.key.word.label",
  APPOINTMENTS_AMOUNT_LABEL: "appointments.amount.label",
  APPOINTMENTS_MULTIPLE_DRIVES_LABEL: "appointments.multiple.drives.label",
  APPOINTMENTS_OXYGEN_LABEL: "appointments.oxygen.label",
  APPOINTMENTS_DSA_LABEL: "appointments.dsa.label",
  APPOINTMENTS_POSITION_LYING_LABEL: "appointmentems.position.lying.label",
  APPOINTMENTS_POSITION_SITTING_LABEL: "appointmentems.position.sitting.label",
  APPOINTMENTS_RETURN_LABEL: "appointments.return.label",
  APPOINTMENTS_ROUND_TRIP_LABEL: "appointments.round.trip.label",
  APPOINTMENTS_ISOLATION_LABEL: "appointments.isolation.label",
  APPOINTMENTS_COMMENT_LABEL: "appointments.comment.label",
  APPOINTMENTS_NEXT_APPOINTMENT_LABEL: "appointments.next.appointment.label",
  APPOINTMENTS_SHOW_COMMENT_LABEL: "appointments.show.comment.label",
  APPOINTMENTS_MULTI_TRIPS_LABEL: "appointments.multi.trips.label",
  APPOINTMENTS_PHONE_LABEL: "appointment.phoneWork.label",

  APPOINTMENTS_RETURN_PHONE: "appointment.carIdReturn.phone",

  APPOINTMENTS_DESINFECTION_LABEL: "appointments.desinfection.label",
  APPOINTMENTS_PERFUSOR_LABEL: "appointments.perfusor.label",

  APPOINTMENTS_CORONA_LABEL: "appointments.corona.label",

  APPOINTMENTS_DEPARTURE_TIME_LABEL: "appointments.departure.time.label",
  APPOINTMENTS_DESTINATION_TIME_LABEL: "appointments.destination.time.label",
  APPOINTMENTS_RETURN_TIME_LABEL: "appointment.returnAppointment.label",

  APPOINTMENTS_DEPARTURE_LABEL: "appointments.departure.label",
  APPOINTMENTS_DESTINATION_LABEL: "appointments.destination.label",
  APPOINTMENTS_SELECT_ADDRESS_LABEL: "appointments.select.address.label",
  APPOINTMENTS_INFUSION_LABEL: "appointments.infusion.label",

  APPOINTMENTS_DRIVE_VEHICLE: "appointments.drive.vehicle",
  APPOINTMENTS_RETURN_VEHICLE: "appointments.return.vehicle",

  APPOINTMENTS_CREATE_CUSTOMER_LABEL: "appointments.create.customer.label",

  APPOINTMENTS_CREATE_BUTTON_LABEL: "appointments.create.button.label",
  APPOINTMENTS_SAVE_BUTTON_LABEL: "appointments.save.button.label",

  APPOINTMENTS_NO_CUSTOMER_ASSIGNED_NOTICE:
    "appointments.no.customer.assigned.notice",
  APPOINTMENTS_NO_CUSTOMER_NOTICE: "appointments.no.customer.notice",
  APPOINTMENTS_NO_TO_ADDRESS_NOTICE: "appointments.no.to.address",
  APPOINTMENTS_NO_FROM_ADDRESS_NOTICE: "appointments.no.from.address",

  APPOINTMENTS_CUSTOMER_ADDRESS_WARNING:
    "appointments.customer.address.warning",

  APPOINTMENTS_DELETE_BUTTON_LABEL: "appointments.delete.button.label",
  APPOINTMENTS_DELETE_SUCCESS: "appointments.delete.success",
  APPOINTMENTS_DELETE_FAIL: "appointments.delete.fail",
  APPOINTMENTS_DELETE_NOTICE: "appointments.delete.notice",

  APPOINTMENTS_DRIVER_MESSAGE: "appointments.driver.message",
  APPOINTMENTS_DRIVER_MESSAGE_SENT: "appointments.driver.message.sent",
  APPOINTMENTS_DRIVER_MESSAGE_RECEIVED: "appointments.driver.message.received",
  APPOINTMENTS_DRIVER_MESSAGE_READ: "appointments.driver.message.read",

  APPOINTMENTS_DRIVER_REPORT_SAVE: "appointments.driver.report.save",
  APPOINTMENTS_DRIVER_REPORT_CANCEL: "appointments.driver.report.cancel",
  APPOINTMENTS_DRIVER_REPORT_NEW: "appointments.driver.report.new",
  APPOINTMENTS_DRIVER_REPORT_EDIT: "appointments.driver.report.edit",
  APPOINTMENTS_DRIVER_REPORT_EDIT_BUTTON:
    "appointments.driver.report.edit.button",

  APPOINTMENTS_DRIVER_SAVE_ERROR_TITLE_LABEL:
    "appointments.driver.save.error.title.label",
  APPOINTMENTS_DRIVER_SAVE_ERROR_DETAILS:
    "appointments.driver.save.error.details",

  BILLS_FILTER_TITLE_LABEL: "bills.filter.title.label",
  BILLS_FILTER_CUSTOMER_LABEL: "bills.filter.customer.label",
  BILLS_FILTER_ADDRESS_LABEL: "bills.filter.address.label",
  BILLS_FILTER_KEYWORDS: "bills.filter.keywords",
  BILLS_FILTER_BILL_NUMBER: "bills.filter.bill.number",
  BILLS_FILTER_STATUS: "bills.filter.status",
  BILLS_FILTER_EXCLUDE: "bills.filter.exclude",
  BILLS_FILTER_INCLUDE: "bills.filter.include",
  BILLS_FILTER_AMOUNT_MIN: "bills.filter.amount.min",
  BILLS_FILTER_AMOUNT_MAX: "bills.filter.amount.max",
  BILLS_FILTER_INCLUDE_INACTIVE: "bills.filter.include.inactive",
  BILLS_FILTER_EXCLUDE_INACTIVE: "bills.filter.exclude.inactive",
  BILLS_FILTER_FROM_DATE: "bills.filter.from.date",
  BILLS_FILTER_TO_DATE: "bills.filter.to.date",
  BILLS_FILTER_INCLUDE_UNPAID: "bills.filter.include.unpaid",
  BILLS_FILTER_EXCLUDE_UNPAID: "bills.filter.exclude.unpaid",

  BILLS_NUMBER_LABEL: "bills.number.label",
  BILLS_CUSTOMER_LABEL: "bills.customer.label",
  BILLS_AMOUNT_DUE_LABEL: "bills.amount.due.label",
  BILLS_AMOUNT_PAID_LABEL: "bills.amount.paid.label",
  BILLS_AMOUNT_REMAINING_LABEL: "bills.amount.remaining.label",
  BILLS_HOSPITAL_WARNING_LABEL: "bills.hospital.warning.label",

  BILLS_TOTAL_AMOUNT_LABEL: "bills.total.amount.label",
  BILLS_TOTAL_PAID_LABEL: "bills.total.paid.label",

  BILLS_TITLE_LABEL: "bills.title.label",
  BILLS_SELECT_BILL_LABEL: "bills.select.bill.label",
  BILLS_BILL_NUMBER_LABEL: "bills.bill.number.label",
  BILLS_PASSENGER_LABEL: "bills.passenger.label",
  BILLS_DEPARTURE_LABEL: "bills.departure.label",
  BILLS_DESTINATION_LABEL: "bills.destination.label",
  BILLS_DEPARTURE_TEXT_LABEL: "bills.departure.text.label",
  BILLS_DESTINATION_TEXT_LABEL: "bills.destination.text.label",
  BILLS_COMMENT_INTERNAL_LABEL: "bills.comment.internal.label",
  BILLS_COMMENT_EXTERNAL_LABEL: "bills.comment.external.label",
  BILLS_TRANSPORT_TICKET_LABEL: "bills.transport.ticket.label",

  BILLS_TRANSPORT_DATE_LABEL: "bills.service.date.label",
  BILLS_ORDER_DATE_LABEL: "bills.order.date.label",
  BILLS_FIRST_REMINDER_DATE_LABEL: "bills.first.reminder.date.label",
  BILLS_SECOND_REMINDER_DATE_LABEL: "bills.second.reminder.date.label",
  BILLS_DAS_DATE_LABEL: "bills.das.date.label",

  BILLS_FULL_CHARGE_LABEL: "bills.full.charge.label",
  BILLS_PARTIAL_CHARGE_LABEL: "bills.partial.charge.label",
  BILLS_CREATE_BUTTON_LABEL: "bills.create.button.label",
  BILLS_SAVE_BUTTON_LABEL: "bills.save.button.label",
  BILLS_DAS_LABEL: "bills.das.label",
  BILLS_IS_OVERWEIGHT_LABEL: "appointment.overweight.label",
  BILLS_WEIGHT_LABEL: "bills.weight.label",
  BILLS_HAS_STAIRS_LABEL: "appointment.stairs.label",
  BILLS_NUMBER_STAIRS_LABEL: "bills.number.stairs.label",
  BILLS_WHEELCHAIR_LABEL: "appointment.ownWheelchair.label",
  BILLS_ELEVATOR_LABEL: "appointment.elevator.label",

  BILLS_REMINDER_UPDATE_LABEL: "bills.reminder.update.label",

  BILLS_POSITIONS_TITLE: "bills.positions.title",
  BILLS_POSITIONS_DESCRIPTION_LABEL: "bills.positions.description.label",
  BILLS_POSITIONS_QUANTITY_LABEL: "bills.positions.quantity.label",
  BILLS_POSITIONS_AMOUNT_LABEL: "bills.positions.amount.label",
  BILLS_POSITIONS_TOTAL_AMOUNT_LABEL: "bills.positions.total.amount.label",
  BILLS_POSITIONS_PRESET_LABEL: "bills.positions.preset.label",
  BILLS_ADD_POSITION_BUTTON_LABEL: "bills.add.position.button.label",
  BILLS_ADD_POSITION_DIALOG_TITLE: "bills.add.position.dialog.title",
  BILLS_EDIT_POSITION_BUTTON_LABEL: "bills.edit.position.button.label",
  BILLS_EDIT_POSITION_DIALOG_TITLE: "bills.edit.position.dialog.title",
  BILLS_REMOVE_POSITION_DIALOG_TITLE: "bills.remove.position.dialog.title",

  /* NEW INPUTS */
  BILLS_HOSPITAL_ORDER_NUMBER_LABEL: "bills.hospital.order.number.label",

  BILLS_HOSPITAL_NB_ROUTES_LABEL: "bills.hospital.nb.routes.label",
  BILLS_HOSPITAL_NB_ROUTES_SINGLE_LABEL:
    "bills.hospital.nb.routes.simple.label",
  BILLS_HOSPITAL_NB_ROUTES_TWO_WAY_LABEL:
    "bills.hospital.nb.routes.twoway.label",
  BILLS_HOSPITAL_NB_ROUTES_MULTIPLE_LABEL:
    "bills.hospital.nb.routes.multiple.label",

  BILLS_HOSPITAL_POSITION_LABEL: "bills.hospital.position.label",
  BILLS_HOSPITAL_POSITION_AMBULANCE_LABEL:
    "bills.hospital.position.ambulance.label",
  BILLS_HOSPITAL_POSITION_CADDY_LABEL: "bills.hospital.position.caddy.label",
  BILLS_HOSPITAL_POSITION_TAXI_LABEL: "bills.hospital.position.taxi.label",

  BILLS_GENERATING_MESSAGE: "bills.generating.message",

  BILLS_ALL_REMINDER_LABEL: "bills.all.reminder.label",
  BILLS_ALL_EXPORT_LABEL: "bills.all.export.label",
  BILLS_EXPORT_TO_PEPPOL: "bills.export.to.peppol.label",
  BILLS_PEPPOL_EXPORT_LABEL: "bills.peppol.export.label",
  BILLS_PEPPOL_RESEND_LABEL: "bills.peppol.resend.label",
  BILLS_PEPPOL_ADDRESSES_LABEL: "bills.peppol.addresses.label",
  BILLS_PEPPOL_SELECT_ADDRESSES_LABEL: "bills.peppol.select.addresses.label",
  BILLS_MULTI_REMINDER_LABEL: "bills.multi.reminder.label",
  BILLS_MULTI_REPORT_LABEL: "bills.multi.report.label",
  BILLS_MULTI_STATUS_LABEL: "bills.multi.status.label",
  BILLS_MULTI_BILLING_LABEL: "bills.multi.billing.label",
  BILLS_MULTI_BILLING_CREATE_LABEL: "bills.multi.billing.create.label",
  BILLS_MULTI_BILLING_UPDATE_STATE_LABEL:
    "bills.multi.billing.update.state.label",
  BILLS_MULTI_BILLING_FILL_LAST: "bills.multi.billing.fill.last",
  BILLS_MULTI_FOOTER_LABEL: "bills.multi.footer.label",
  BILLS_MULTI_EXPORT_LABEL: "bills.multi.export.label",
  BILLS_MULTI_EXPORT_SORT_TITLE: "bills.multi.export.sort.title",
  BILLS_MULTI_EXPORT_SORT_SUMMARY: "bills.multi.export.sort.summary",

  BILLS_PEPPOL_EXPORT_SUCCESS: "bills.peppol.export.success",
  BILLS_PEPPOL_EXPORT_FAIL: "bills.peppol.export.fail",
  BILLS_PEPPOL_NO_INVOICES_FOUND: "bills.peppol.export.no.invoices.found",

  BILLS_MULTI_STATUS_SUCCESS: "bills.multi.status.success",

  BILLS_MULTI_EXPORT_SORT_ORDER_BILL_NUMBER_ASC:
    "bills.multi.export.sort.order.date.asc",
  BILLS_MULTI_EXPORT_SORT_ORDER_BILL_NUMBER_DESC:
    "bills.multi.export.sort.order.date.desc",
  BILLS_MULTI_EXPORT_SORT_ORDER_CUSTOMER_ASC:
    "bills.multi.export.sort.order.customer.asc",

  BILLS_FILES_TITLE: "bills.files.title",
  BILLS_FILES_CHOOSE_LABEL: "bills.files.choose.label",
  BILLS_FILES_UPLOAD_LABEL: "bills.files. upload.label",
  BILLS_FILES_REPORT_LABEL: "bills.files.report.label",
  BILLS_FILES_WARNING_LABEL: "bills.files.warning.label",
  BILLS_FILES_MERGE_LABEL: "bills.files.merge.label",
  BILLS_FILES_SEND_LABEL: "bills.files.send.label",
  BILLS_FILES_EMPTY_LABEL: "bills.files.empty.label",
  BILLS_FILES_BILL_PDF_TITLE: "bills.files.bill.pdf.title",
  BILLS_FILES_ADDITIONAL_FILES_TITLE: "bills.files.additional.files.title",

  BILLS_FILES_UPLOAD_SUCCESS: "bills.files.upload.success",
  BILLS_FILES_REMOVE_SUCCESS: "bills.files.removes.success",

  BILLS_FILES_ERROR_UPLOAD: "bills.files.error.upload",
  BILLS_FILES_ERROR_SIZE_SUMMARY: "bills.files.error.size.summary",
  BILLS_FILES_ERROR_SIZE_DETAIL: "bills.files.error.size.detail",

  BILLS_VALIDATION_ERROR_BILL_NUMBER: "bills.validation.error.bill.number",
  BILLS_VALIDATION_ERROR_STATUS: "bills.validation.error.status",
  BILLS_VALIDATION_ERROR_TRANSPORT_TYPE:
    "bills.validation.error.transport.type",
  BILLS_VALIDATION_ERROR_BILLING_DATE: "bills.validation.error.billing.date",
  BILLS_VALIDATION_ERROR_CUSTOMER: "bills.validation.error.customer",
  BILLS_VALIDATION_ERROR_ADDRESS: "bills.validation.error.address",
  BILLS_VALIDATION_ERROR_FROM_TEXT: "bills.validation.error.from.text",
  BILLS_VALIDATION_ERROR_TO_TEXT: "bills.validation.error.to.text",
  BILLS_VALIDATION_WARNING_NO_FILE: "bills.validation.warning.no.file",

  BILLS_WARNING_UPLOAD_DISABLED: "bills.warning.upload.disabled",

  PAYMENTS_FILTER_TITLE_LABEL: "payments.filter.title.label",
  PAYMENTS_SELECT_PAYMENT_LABEL: "payments.select.payment.label",
  PAYMENTS_TITLE_LABEL: "payments.title.label",
  PAYMENTS_FILTER_MIN_AMOUNT_LABEL: "payments.filter.min.amount.label",
  PAYMENTS_FILTER_MAX_AMOUNT_LABEL: "payments.filter.max.amount.label",
  PAYMENTS_FILTER_FROM_DATE_LABEL: "payments.filter.from.date.label",
  PAYMENTS_FILTER_TO_DATE_LABEL: "payments.filter.to.date.label",
  PAYMENTS_FILTER_CUSTOMER_LABEL: "payments.filter.customer.label",
  PAYMENTS_FILTER_BILL_LABEL: "payments.filter.bill.label",

  PAYMENTS_FILTER_ADDRESS_KEY_LABEL: "payments.filter.address.key.label",
  PAYMENTS_FILTER_PAYMENT_KEY_LABEL: "payments.filter.payment.key.label",
  PAYMENTS_FILTER_CUSTOMER_KEY_LABEL: "payments.filter.customer.key.label",
  PAYMENTS_FILTER_BILL_KEY_LABEL: "payments.filter.bill.key.label",
  PAYMENTS_FILTER_PAYMENT_FROM_DATE_LABEL:
    "payments.filter.payment.from.date.label",
  PAYMENTS_FILTER_PAYMENT_TO_DATE_LABEL:
    "payments.filter.payment.to.date.label",

  PAYMENTS_SAVE_BUTTON_LABEL: "payments.save.button.label",
  PAYMENTS_CREATE_BUTTON_LABEL: "payments.create.button.label",

  PAYMENTS_CUSTOMER_LABEL: "payments.customer.label",
  PAYMENTS_BILLING_ADDRESS_LABEL: "payments.billing.address.label",
  PAYMENTS_BILL_NUMBER_LABEL: "payment.bill.number.label",
  PAYMENTS_DATE_LABEL: "payments.date.label",
  PAYMENTS_AMOUNT_LABEL: "payments.amount.label",
  PAYMENTS_BANK_LABEL: "payments.bank.label",
  PAYMENTS_BILL_TITLE_LABEL: "payments.bill.title.label",
  PAYMENTS_BILL_NOT_SELECTED: "payments.bill.not.selected",

  PAYMENT_SAVE_SUCCESS_MESSAGE: "payments.save.success.message",
  PAYMENT_DELETE_SUCCESS_MESSAGE: "payment.delete.success.message",
  PAYMENT_DELETE_FAILURE_MESSAGE: "payment.delete.failure.message",

  PAYMENTS_VALIDATION_ERROR_BANK: "payments.validation.error.bank",
  PAYMENTS_VALIDATION_ERROR_CUSTOMER: "payments.validation.error.customer",
  PAYMENTS_VALIDATION_ERROR_BILL: "payments.validation.error.bill",
  PAYMENTS_VALIDATION_ERROR_INVALID_AMOUNT:
    "payments.validation.error.invalid.amount",
  PAYMENTS_VALIDATION_ERROR_BILLING_DATE:
    "payments.validation.error.billing.date",

  PEPPOL_SET_RESEND_SUCCESS: "peppol.set.resend.success",
  PEPPOL_SEND_BILL_SUCCESS: "peppol.send.bill.success",
  PEPPOL_SEND_BILL_FAILURE: "peppol.send.bill.failure",

  PRODUCTS_NAME_LABEL: "products.name.label",
  PRODUCTS_PRICE_LABEL: "products.price.label",
  PRODUCTS_QUANTITY_LABEL: "products.quantity.label",
  PRODUCTS_NO_SELECTION_TITLE: "products.no.selection.title",
  PRODUCTS_NEW_ENTRY_TITLE: "products.new.entry.title",
  PRODUCTS_EDIT_ENTRY_TITLE: "products.edit.entry.title",
  PRODUCTS_SAVE_BUTTON_LABEL: "products.save.button.label",
  PRODUCTS_CREATE_BUTTON_LABEL: "products.create.button.label",

  PRODUCTS_VALIDATION_NAME_REQUIRED: "products.validation.name.required",

  VEHICLES_NEW_ENTRY_TITLE: "vehicles.new.entry.title",
  VEHICLES_EDIT_ENTRY_TITLE: "vehicles.edit.entry.title",
  VEHICLES_NO_SELECTION_LABEL: "vehicles.no.selection.label",
  VEHICLES_TYPE_LABEL: "vehicles.type.label",
  VEHICLES_AVAILABLE_LABEL: "vehicles.available.label",
  VEHICLES_YEAR_LABEL: "vehicles.year.label",
  VEHICLES_KM_LABEL: "vehicles.km.label",
  VEHICLES_LICENSE_PLATE_LABEL: "vehicles.license.plate.label",
  VEHICLES_PHONE_LABEL: "vehicles.phone.label",
  VEHICLES_COMMENT_LABEL: "vehicles.comment.label",
  VEHICLES_WINFLEET_NAME_LABEL: "vehicles.winfleet.name.label",

  VEHICLES_SAVE_BUTTON_LABEL: "vehicles.save.button.label",
  VEHICLES_CREATE_BUTTON_LABEL: "vehicles.create.button.label",

  VEHICLES_VALIDATION_PLATE_REQUIRED: "vehicles.validation.plate.required",
  VEHICLES_VALIDATION_PLATE_INVALID: "vehicles.validation.plate.invalid",
  VEHICLES_VALIDATION_TYPE_REQUIRED: "vehicles.validation.type.required",

  APPOINTMENTS_UPDATE_SUCCESS_MESSAGE: "appointments.update.success.message",

  SETTINGS_ACCOUNT_NUMBER_LABEL: "settings.account.number.label",
  SETTINGS_ACCOUNT_BILL_TEXT_LABEL: "settings.account.bill.text.label",
  SETTINGS_SAVE_BUTTON_LABEL: "settings.save.button.label",
  SETTINGS_VALIDATION_ACCOUNT_NUMBER_REQUIRED:
    "settings.validation.account.number.required",
  SETTINGS_UPDATE_SUCCESS_MESSAGE: "settings.update.success.message",

  SHIFTS_CREATE_BUTTON_LABEL: "shifts.create.buttons.label",
  SHIFTS_EDIT_BUTTON_LABEL: "shifts.edit.button.label",
  SHIFTS_DELETE_BUTTON_LABEL: "shifts.delete.button.label",
  SHIFTS_EVENT_SUMMARY_LABEL: "shifts.event.summary.label",
  SHIFTS_NO_MEMBERS_LABEL: "shifts.no.members.label",
  SHIFTS_NO_SHIFT_LABEL: "shifts.no.shift.label",
  SHIFTS_ERROR_NO_SHIFT: "shifts.error.no.shift",
  SHIFTS_DUTY_LABEL: "shifts.duty.label",
  SHIFTS_DELETE_SUCCESS: "shifts.delete.success",
  SHIFTS_UPDATE_SUCCESS: "shifts.update.success",
  SHIFTS_UPDATE_FAIL: "shifts.update.fail",
  SHIFTS_MEMBERS_PLACEHOLDER: "shifts.members.placeholder",

  ON_CALLS_REGISTER_BUTTON_LABEL: "on.calls.register.button.label",
  ON_CALLS_UNREGISTER_BUTTON_LABEL: "on.calls.unregister.button.label",
  ON_CALLS_EDIT_BUTTON_LABEL: "on.calls.edit.button.label",
  ON_CALLS_NUMBER_SLOTS_LABEL: "on.calls.number.slots.label",
  ON_CALLS_SLOTS_OCCUPIED_LABEL: "on.calls.slots.occupied.label",
  ON_CALLS_SHIFT_FULL_LABEL: "on.calls.shift.full.label",
  ON_CALLS_SHIFT_EMPTY_LABEL: "on.calls.shift.empty.label",
  ON_CALLS_DRIVERS_TITLE: "on.calls.drivers.title",
  ON_CALLS_NEW_SHIFT_TITLE: "on.calls.new.shift.title",
  ON_CALLS_LOW_SLOTS_WARNING: "on.calls.low.slots.warning",
  ON_CALLS_TYPES_WARNING: "on.call.types.warning",
  ON_CALLS_CONFIRMED: "on.calls.confirmed",

  VACATIONS_REPORT_CURRENT_LABEL: "vacations.report.current.label",
  VACATIONS_MY_VACATIONS_LABEL: "vacations.my.vacations.label",
  VACATIONS_START_LABEL: "vacations.start.label",
  VACATIONS_END_LABEL: "vacations.end.label",
  VACATIONS_COMMENT_USER_LABEL: "vacations.comment.user.label",
  VACATIONS_COMMENT_ADMIN_LABEL: "vacations.comment.admin.label",
  VACATIONS_STATUS_LABEL: "vacations.status.label",
  VACATIONS_CREATE_BUTTON_LABEL: "vacations.create.button.label",
  VACATIONS_CREATE_ADMIN_BUTTON_LABEL: "vacations.create.admin.button.label",
  VACATIONS_UPDATE_BUTTON_LABEL: "vacations.update.button.label",
  VACATIONS_DELETE_BUTTON_LABEL: "vacations.delete.button.label",
  VACATIONS_CREATE_DIALOG_TITLE: "vacations.create.dialog.title",
  VACATIONS_EDIT_DIALOG_TITLE: "vacations.edit.dialog.title",
  VACATIONS_NO_VACATIONS_LABEL: "vacations.no.vacations.label",
  VACATIONS_AVAILABLE_LABEL: "vacations.available.label",
  VACATIONS_HALF_DAY_LABEL: "vacations.half.day.label",
  VACATIONS_SICK_DAY_LABEL: "vacations.sick.day.label",
  VACATIONS_SPECIAL_LABEL: "vacations.special.label",

  VACATIONS_SHOW_ALL_LABEL: "vacations.show.all.label",

  VACATIONS_VACATION_LABEL: "vacations.vacation.label",

  VACATIONS_HOLIDAY_TITLE_LABEL: "vacations.holiday.title.label",
  VACATIONS_HOLIDAY_LABEL: "vacations.holiday.label",
  VACATION_SAVE_HOLIDAY_LABEL: "vacation.save.holiday.label",
  VACATIONS_HOLIDAY_UPDATE_SUCCESS_MESSAGE:
    "vacations.holiday.update.success.message",
  VACATIONS_HOLIDAY_UPDATE_FAILURE_MESSAGE:
    "vacations.holiday.update.failure.message",
  VACATIONS_HOLIDAY_DELETE_SUCCESS_MESSAGE:
    "vacations.holiday.delete.success.message",
  VACATIONS_HOLIDAY_DELETE_FAILURE_MESSAGE:
    "vacations.holiday.delete.failure.message",
  VACATIONS_HOLIDAY_DELETE_WARNING: "vacations.holiday.delete.warning",

  VACATIONS_SET_OLD: "vacations.set.old",
  VACATIONS_SET_OLD_CONFIRM: "vacations.set.old.confirm",
  VACATIONS_SET_OLD_FAIL: "vacations.set.old.fail",

  VACATION_DATE_VALIDATION_FAIL: "vacation.date.validation.fail",
  VACATION_USER_VALIDATION_FAIL: "vacation.user.validation.fail",

  VACATION_UPDATE_SUCCESS_MESSAGE: "vacation.update.success.message",
  VACATION_UPDATE_FAILURE_MESSAGE: "vacation.update.failure.message",
  VACATION_DELETE_SUCCESS_MESSAGE: "vacation.delete.success.message",

  REGULARS_START: "regulars.start",
  REGULARS_END: "regulars.end",
  REGULARS_ID: "regulars.id",
  REGULARS_DATES: "regulars.date",
  REGULARS_DELETE_CONFIRM: "regulars.delete.confirm",
  REGULARS_DELETE_SUCCESS: "regulars.delete.success",
  REGULARS_DELETE_FAIL: "regulars.delete.fail",

  DRIVES_HEADER_DAY: "drives.header.day",
  DRIVES_HEADER_GARAGE_START: "drives.header.garage.start",
  DRIVES_HEADER_GARAGE_END: "drives.header.garage.end",
  DRIVES_HEADER_DATE: "drives.header.date",
  DRIVES_HEADER_NAME: "drives.header.name",
  DRIVES_HEADER_PAUSE: "drives.header.pause",

  DRIVERS_HEADER_CHECKED: "drivers.header.checked",
  DRIVERS_HEADER_NEXT: "drivers.header.next",
  DRIVERS_HEADER_BLOCKED: "drivers.header.blocked",
  DRIVERS_HEADER_CONTROLLED: "drivers.header.controlled",

  DRIVES_TABLE_RETURN: "drives.table.return",
  DRIVES_TABLE_START_TIME: "drives.table.start.time",
  DRIVES_TABLE_PATIENT: "drives.table.patient",
  DRIVES_TABLE_START_ADDRESS: "drives.table.start.address",
  DRIVES_TABLE_DESTINATION_ADDRESS: "drives.table.destination.address",
  DRIVES_TABLE_MEETING_TIME: "drives.table.meeting.time",
  DRIVES_TABLE_VEHICLE: "drives.table.vehicle",
  DRIVES_TABLE_BUTTON_START: "drives.table.button.start",

  DRIVES_TIMES_RESPONSIVE: "drives.times.responsive",
  DRIVES_ADDRESS_RESPONSIVE: "drives.address.responsive",
  DRIVES_TIME_START_RESPONSIVE: "drives.time.start.responsive",
  DRIVES_TIME_MEETING_RESPONSIVE: "drives.time.meeting.responsive",
  DRIVES_DEPARTURE_RESPONSIVE: "drives.departure.responsive",
  DRIVES_ARRIVAL_RESPONSIVE: "drives.arrival.responsive",

  DRIVES_REFRESH: "drives.refresh",
  DRIVES_SAVE_TIMES: "drives.save.times",

  DRIVES_SAVE_DAILY_REPORT: "drives.save.daily.report",
  DRIVES_PRINT_DAILY_REPORT: "drives.print.daily.report",
  DRIVES_PRINT_TRANSPORT_REPORT: "drives.print.transport.report",
  DRIVES_PRINT_MONTHLY_REPORT: "drives.print.monthly.report",
  DRIVES_REUPLOAD: "drives.reupload",

  DRIVES_WS_TITLE: "drives.ws.title",
  DRIVES_WS_DELETE: "drives.ws.delete",
  DRIVES_WS_UPDATE: "drives.ws.udpate",

  DRIVES_SIGNATURE_HEADER: "drives.signature.header",
  DRIVES_SIGNATURE_CLEAR: "drives.signature.clear",
  DRIVES_SIGNATURE_SAVE: "drives.signature.save",

  DRIVES_NO_APPOINTMENT: "drives.no.appointment",
  DRIVES_CITY: "drives.city",
  DRIVES_PAPER_WAIT: "drives.paper.wait",
  DRIVES_MINUTES: "drives.minutes",
  DRIVES_HOURS_DEPARTURE: "drives.hours.departure",
  DRIVES_HOURS_ARRIVAL: "drives.hours.arrival",
  DRIVES_ADMISSION: "drives.admission",
  DRIVES_KILOMETERS: "drives.kilometers",

  DRIVES_DETAILS_TITLE: "drives.details.title",
  DRIVES_TRANSPORT_TITLE: "drives.transport.title",
  DRIVES_INFO_TITLE: "drives.info.title",

  DRIVES_DAILY_SAVE_SUCCESS: "drives.daily.save.success",
  DRIVES_DAILY_SAVE_FAIL: "drives.daily.save.fail",
  DRIVES_DAILY_ADDITIONAL_SAVE_SUCCESS: "drives.daily.additional.save.success",
  DRIVES_DAILY_ADDITIONAL_SAVE_FAIL: "drives.daily.additional.save.fail",

  DRIVER_GENERATE_MONTHLY_REPORT_FAIL: "driver.generate.monthly.report.fail",
  DRIVER_GENERATE_TRANSPORT_REPORT_FAIL:
    "driver.generate.transport.report.fail",

  DRIVES_REPORT_VIEW: "drives.report.view",
  DRIVES_WORK_VIEW: "drives.work.view",

  MONTH_REPORT_SEARCH_NAME: "month.report.search.name",

  ERROR_DUPLICATE: "error.duplicate",
  ERROR_MISSING_VALUES: "error.missing.values",
  ERROR_DATA_FETCH: "error.data.fetch",
  ERROR_NO_DATA: "error.no.data",
  ERROR_RENDER: "error.render",
  ERROR: "error",
  ERROR_SERVER_NOT_AVAILABLE: "error.server.not.available",
  ERROR_DATA_SAVE: "error.data.save",
  ERROR_TOKEN_EXPIRED: "error.token.expired",

  WARNING_CONFIRM_REMOVE: "warning.confirm.remove",
  WARNING_UNSAVED_CHANGES: "warning.unsaved.changes",
  WARNING_VALIDATION_FAILED: "warning.validation.failed",
  WARNING_OFFLINE_MODE: "warning.offline.mode",

  DIALOG_WARNING_TITLE: "dialog.warning.title",

  DIALOG_CONTINUE_OFFLINE_BUTTON_LABEL: "dialog.continue.offline.button.label",
  DIALOG_SAVE_BUTTON_LABEL: "dialog.save.button.label",
  DIALOG_CANCEL_BUTTON_LABEL: "dialog.cancel.button.label",
  DIALOG_CANCEL_SHORT_BUTTON_LABEL: "dialog.cancel.short.button.label",
  DIALOG_CONFIRM_BUTTON_LABEL: "dialog.confirm.button.label",
  DIALOG_DENY_BUTTON_LABEL: "dialog.deny.button.label",
  DIALOG_SEARCH_BUTTON_LABEL: "dialog.search.button.label",
  DIALOG_CLEAR_FILTER_LABEL: "dialog.clear.filter.label",
  DIALOG_CONTINUE_BUTTON_LABEL: "dialog.continue.button.label",
  DIALOG_BACK_BUTTON_LABEL: "dialog.back.button.label",
  DIALOG_DOWNLOAD_BUTTON_LABEL: "dialog.download.button.label",
  DIALOG_CLOSE_BUTTON_LABEL: "dialog.close.button.label",

  RESET_VALUES: "reset.values",

  ROLE_OFFLINE: "ROLE_OFFLINE",
  ROLE_USER: "ROLE_USER",
  ROLE_INVOICE: "ROLE_INVOICE",
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_PLANNING: "ROLE_PLANNING",
  ROLE_DRIVER: "ROLE_DRIVER",
  ROLE_FIDU: "ROLE_FIDU",
  ROLE_AUTHOR: "ROLE_AUTHOR",

  GENDER_NONE: "gender.none",
  GENDER_MALE: "gender.male",
  GENDER_FEMALE: "gender.female",
  GENDER_UNDEFINED: "gender.undefined",

  TITLES_NONE: "no Title",
  TITLES_NO: "titles.no",
  TITLES_MISTER: "Mister",
  TITLES_MISS: "Miss",
  TITLES_MISSES: "Misses",
  TITLES_DOCTOR: "Doctor",
  TITLES_MASTER: "Master",
  TITLES_PROFESSOR: "Professor",
  TITLES_MYLORD: "Monseigneur",
  TITLES_GENTLEMEN: "Messieurs",
  TITLES_SENIOR: "Senior",
  TITLES_CHILD: "Child",

  APPOINTMENT_STATUS_NONE: "appointment.status.none",
  APPOINTMENT_STATUS_RETURN: "appointment.status.return",
  APPOINTMENT_STATUS_OUTWARD: "appointment.status.outward",
  APPOINTMENT_STATUS_EXECUTED: "appointment.status.executed",
  APPOINTMENT_STATUS_WAITING: "appointment.status.waiting",
  APPOINTMENT_STATUS_INVOICED: "appointment.status.invoiced",
  APPOINTMENT_STATUS_CANCELLED: "appointment.status.cancelled",

  APPOINTMENT_STATUS_PENDING: "appointment.status.pending",
  APPOINTMENT_STATUS_BILLED: "appointment.status.billed",

  APPOINTMENT_CANCEL_SELECTED_LABEL: "appointment.status.cancel.selected.label",
  APPOINTMENT_CANCEL_ERROR: "appointment.status.cancel.error",
  APPOINTMENT_CANCEL_SUCCESS: "appointment.status.cancel.success",
  APPOINTMENT_CANCEL_NO_SELECTED: "appointment.status.cancel.no.selected",

  TRANSPORT_TYPE_NONE: "transport.type.none",
  TRANSPORT_TYPE_AMBULANCE: "transport.type.ambulance",
  TRANSPORT_TYPE_CADDY: "transport.type.caddy",
  TRANSPORT_TYPE_TAXI: "transport.type.taxi",
  TRANSPORT_TYPE_EMPTY: "transport.type.empty",
  TRANSPORT_TYPE_BUS: "transport.type.bus",
  TRANSPORT_TYPE_VSL: "transport.type.vsl",

  ADDRESS_TYPE_NONE: "address.type.none",
  ADDRESS_TYPE_INVOICE: "address.type.invoice",
  ADDRESS_TYPE_BASE: "address.type.base",
  ADDRESS_TYPE_ADDITIONAL: "address.type.additional",

  BILL_STATUS_NEW: "bill.status.new",
  BILL_STATUS_SENT: "bill.status.sent",
  BILL_STATUS_PARTIALLY_PAID: "bill.status.partially.paid",
  BILL_STATUS_REMINDER_1: "bill.status.reminder.1",
  BILL_STATUS_REMINDER_2: "bill.status.reminder.2",
  BILL_STATUS_DAS: "bill.status.das",
  BILL_STATUS_OVERPAID: "bill.status.overpaid",
  BILL_STATUS_CLOSED: "bill.status.closed",
  BILL_STATUS_DELIVERY_NOTE: "bill.status.delivery.note",

  SHOUTBOX_NO_CONTENT: "shoutbox.no.content",
  SHOUTBOX_NO_MESSAGE: "shoutbox.no.message",
  SHOUTBOX_SEARCH_PLACEHOLDER: "shoutbox.search.placeholder",
  SHOUTBOX_NOTIFICATION_EDIT_TITLE: "shoutbox.notification.edit.title",
  SHOUTBOX_NOTIFICATION_EDIT_REACTION_TITLE:
    "shoutbox.notification.edit_reaction.title",
  SHOUTBOX_NOTIFICATION_DELETE_TITLE: "shoutbox.notification.delete.title",
  SHOUTBOX_NOTIFICATION_EDIT_DEFAULT_BODY:
    "shoutbox.notification.edit.default.body",
  SHOUTBOX_NOTIFICATION_EDIT_REACTION_BODY:
    "shoutbox.notification.edit.reaction.body",
  SHOUTBOX_NOTIFICATION_EDIT_BODY: "shoutbox.notification.edit.body",
  SHOUTBOX_NOTIFICATION_DELETE_BODY: "shoutbox.notification.delete.body",
  SHOUTBOX_DELETE_CONFIRM: "shoutbox.delete.confirm",
  SHOUTBOX_DIALOG_TITLE_ADD: "shoutbox.dialog.title.add",
  SHOUTBOX_DIALOG_TITLE_EDIT: "shoutbox.dialog.title.edit",
  SHOUTBOX_DIALOG_TITLE_DELETE: "shoutbox.dialog.title.delete",
  SHOUTBOX_DIALOG_TITLE_CHAT: "shoutbox.dialog.title.chat",
  SHOUTBOX_IMAGE_SELECT_BUTTON_LABEL: "shoutbox.image.select.button.label",
  SHOUTBOX_SORT_SEVERITY_LABEL: "shoutbox.sort.severity.label",
  SHOUTBOX_SORT_TIME_LABEL: "shoutbox.sort.time.label",
  SHOUTBOX_GROUP_TT_MORE: "shoutbox.group.tt.more",
  SHOUTBOX_GROUP_EMPTY_MESSAGE: "shoutbox.group.empty.message",
  SHOUTBOX_EDIT_FAIL_MESSAGE: "shoutbox.edit.fail.message",
  SHOUTBOX_DELETE_FAIL_MESSAGE: "shoutbox.delete.fail.message",

  MONTH_REPORT_NAME: "month.report.name",
  MONTH_REPORT_TOTAL: "month.report.total",

  MONDAY_LONG: "monday.long",
  TUESDAY_LONG: "tuesday.long",
  WEDNESDAY_LONG: "wednesday.long",
  THURSDAY_LONG: "thursday.long",
  FRIDAY_LONG: "friday.long",
  SATURDAY_LONG: "saturday.long",
  SUNDAY_LONG: "sunday.long",

  MONDAY_SHORT: "monday.short",
  TUESDAY_SHORT: "tuesday.short",
  WEDNESDAY_SHORT: "wednesday.short",
  THURSDAY_SHORT: "thursday.short",
  FRIDAY_SHORT: "friday.short",
  SATURDAY_SHORT: "saturday.short",
  SUNDAY_SHORT: "sunday.short",

  JANUARY_LONG: "january.long",
  FEBRUARY_LONG: "february.long",
  MARCH_LONG: "march.long",
  APRIL_LONG: "april.long",
  MAY_LONG: "may.long",
  JUNE_LONG: "june.long",
  JULY_LONG: "july.long",
  AUGUST_LONG: "august.long",
  SEPTEMBER_LONG: "september.long",
  OCTOBER_LONG: "october.long",
  NOVEMBER_LONG: "november.long",
  DECEMBER_LONG: "december.long",

  JANUARY_SHORT: "january.short",
  FEBRUARY_SHORT: "february.short",
  MARCH_SHORT: "march.short",
  APRIL_SHORT: "april.short",
  MAY_SHORT: "may.short",
  JUNE_SHORT: "june.short",
  JULY_SHORT: "july.short",
  AUGUST_SHORT: "august.short",
  SEPTEMBER_SHORT: "september.short",
  OCTOBER_SHORT: "october.short",
  NOVEMBER_SHORT: "november.short",
  DECEMBER_SHORT: "december.short",

  PURGE_BUTTON_LABEL: "purge.button.label",

  TODAY: "today",

  VIEWMODE_LIGHT: "viewmode.light",
  VIEWMODE_DARK: "viewmode.dark",

  FUZZY_ON: "fuzzy.on",
  FUZZY_OFF: "fuzzy.off",

  ACTIVE: "active",
  INACTIVE: "inactive",

  CREATED_BY: "created.by",
  CREATED_ON: "created.on",

  EDITED_BY: "edited.by",
  EDITED_ON: "edited.on",

  STARTS_WITH: "starts.with",
  CONTAINS: "p.contains",
  NOT_CONTAINS: "not.contains",
  ENDS_WITH: "ends.with",
  EQUALS: "p-equals",
  NOT_EQUALS: "not.equals",
  NO_FILTER: "no.filter",

  DRIVER_STATUS_SAVE_FAILED: "driver.status.save.failed",
  DRIVER_STATUS_SAVE_SUCCESS: "driver.status.save.success",

  DRIVER_STATUS_AVAILABLE_LABEL: "driver.status.available.label",
  DRIVER_STATUS_IN_GARAGE_AND_AVAILABLE_LABEL:
    "driver.status.in.garage.available.label",
  DRIVER_STATUS_BREAK_LABEL: "driver.status.break.label",
  DRIVER_STATUS_ON_THE_ROAD_TO_PATIENT_LABEL:
    "driver.status.on.the.road.to.patient.label",
  DRIVER_STATUS_ON_THE_ROAD_WITH_PATIENT_LABEL:
    "driver.status.on.the.road.with.patient.label",
  DRIVER_STATUS_WITH_PATIENT_LABEL: "driver.status.with.patient.label",
  DRIVER_STATUS_CLOSING_TIME_LABEL: "driver.status.closing.time.label",
  DRIVER_STATUS_UNLOADING_LABEL: "driver.status.unloading.label",
  DRIVER_STATUS_SORT_LABEL: "driver.status.sort.label",
  DRIVER_STATUS_SORT_DRIVER_NAME_LABEL: "driver.status.sort.driver.label",
  DRIVER_STATUS_SORT_LICENCE_PLATE_LABEL:
    "driver.status.sort.licence.plate.label",
  DRIVER_STATUS_SORT_NEWEST_LABEL: "driver.status.sort.newest.label",
  DRIVER_STATUS_SORT_NO_RESULT: "driver.status.sort.no.result",
  DRIVER_STATUS_SORT_NO_HISTORICAL_VALUES:
    "driver.status.sort.no.historical.values",
  HISTORICAL_APPOINTMENTS_LABEL: "historical.apointments.label",
  HISTORICAL_APPOINTMENTS_NO_RESULT: "historical.appointments.no.result",
  HISTORICAL_APPOINTMENTS_INFO: "historical.appointments.info",
});
/**
 * Contains global input validation rules.
 */
export const VALIDATION_RULES = Object.freeze({
  USERNAME_MIN_LENGTH: 3,
  PASSWORD_MIN_LENGTH: 5,
  MATRICULATION_MIN_LENGTH: 14,
  MATRICULATION_LUCKY_NUMBER_MIN_LENGTH: 5,
  BILL_NUMBER_FORMAT: /^\d+\/\d\d\n+/g,
  BILL_NUMBER_MIN_LENGTH: 3,
  BANK_MIN_LENGTH: 4,
  ZIP_MIN_LENGTH: 4,
  LICENSE_PLATE_MIN_LENGTH: 4,
});
/**
 * Contains the frontend view mode values.
 */
export const VIEW_MODES = Object.freeze({
  LIGHT: { value: "light", label: MESSAGE_KEYS.VIEWMODE_LIGHT },
  DARK: { value: "dark", label: MESSAGE_KEYS.VIEWMODE_DARK },
});
/**
 * Date layout for calendar input fields.
 */
export const DATE_FORMAT = "dd/mm/yy";
/**
 * Default empty query string.
 */
export const EMPTY_FILTER_QUERY = `&queryIsEmtpy=${true}&sort=&sort=yearsort,invnumbersort,desc`;

export const PAGE_LINK_SIZES = Object.freeze({
  DESKTOP: 5,
  MOBILE: 3,
});

export const ROWS_PER_PAGE = [5, 10, 20, 50, 100, 500, 1000];

export const SHIFT_ORDER = {
  12: 1,
  14: 2,
  19: 3,
  13: 4,
  15: 5,
  16: 6,
  21: 7,
  20: 8,
};

export const SHOUTBOX_STATES = Object.freeze({
  SEVERE: {
    stateId: 1,
    bColor: "rgb(210, 0, 0)",
    fColor: "white",
  },
  WARNING: {
    stateId: 2,
    bColor: "rgb(255, 122, 57)",
    fColor: "black",
  },
  INFO: {
    stateId: 3,
    bColor: "rgb(62 128 180)",
    fColor: "white",
  },
});
