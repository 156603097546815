/**
 * This component renders the address input form.
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React from "react";
// PrimeReact components
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { ToggleButton } from "primereact/togglebutton";
import { Checkbox } from "primereact/checkbox";
// Redux
import { connect } from "react-redux";
import { setChangePending } from "actions/sessionActions";
// Responsive
import { isDesktop } from "react-device-detect";
// Custom components
import { FloatingTextInput } from "components/common";
// Localization
import { injectIntl } from "react-intl";
// Static values
import {
  MESSAGE_KEYS,
  VALIDATION_RULES,
  MESSAGE_SEVERITY,
  QUERIES,
} from "assets/staticData/enums";
// Helper functions
import {
  generateValidationError,
  sendQuery,
  initLogger,
  hasValueChanged,
} from "common/Helpers";
// Style
import "./Style.scss";
// Logging
const logger = initLogger("address_edit_layout");

const EMPTY_STATE = {
  inputLine1: "",
  inputLine2: "",
  inputZip: "",
  inputCity: "",
  inputName: "",
  inputSearchkey: "",
  inputActive: true,
  inputPeppolId: "",

  inputIsHospital: false,
  inputIsRetirement: false,
  inputIsAmbulance: false,

  inputPhone1: "",
  inputPhone2: "",
  inputComment: "",

  updatePending: false,

  validLine1: null,
  validZip: null,
  validCity: null,
};

class AddressEditLayout extends React.Component {
  state = {
    ...EMPTY_STATE,
  };

  componentDidMount = () => {
    this.initInputs();
  };

  componentDidUpdate = (prevProps) => {
    const {
      selectedAddress: { addressId },
    } = this.props;
    if (
      !prevProps.selectedAddress ||
      prevProps.selectedAddress.addressId !== addressId
    ) {
      this.initInputs();
    }
  };

  initInputs = () => {
    const { selectedAddress } = this.props;
    if (selectedAddress?.addressId && selectedAddress.addressId > 0) {
      const {
        line1,
        line2,
        zipCode,
        countryProvince,
        name,
        active,
        searchKey,
        peppolParticipantId,

        isHospital,
        isNursingHome,
        isAmbulanceTransport,

        phone1,
        phone2,
        remark,
      } = selectedAddress;
      this.setState({
        ...EMPTY_STATE,
        inputLine1: line1 ?? "",
        inputLine2: line2 ?? "",
        inputPeppolId: peppolParticipantId ?? "",
        inputZip: zipCode ?? "",
        inputCity: countryProvince ?? "",
        inputName: name ?? "",
        inputActive: active ?? false,
        inputSearchkey: searchKey ? searchKey : "",

        inputIsHospital: isHospital === true,
        inputIsRetirement: isNursingHome === true,
        inputIsAmbulance: isAmbulanceTransport === true,

        inputPhone1: phone1 ?? "",
        inputPhone2: phone2 ?? "",
        inputComment: remark ?? "",
      });
    } else {
      this.setState({
        ...EMPTY_STATE,
      });
    }
  };

  /** Check if required inputs have values & add new address if true. Display error messages if false. */
  validateInputs = () => {
    return new Promise((resolve, reject) => {
      const { intl, selectedAddress } = this.props;
      const {
        inputCity,
        inputLine1,
        inputLine2,
        inputPeppolId,

        inputZip,
        inputName,
        inputActive,
        inputSearchkey,

        inputIsHospital,
        inputIsRetirement,
        inputIsAmbulance,

        inputComment,
        inputPhone1,
        inputPhone2,
      } = this.state;
      let errors = [];
      let validLine1,
        validZip,
        validCity = null;
      const {
        ADDRESSES_ERROR_LINE1_REQUIRED,
        ADDRESSES_ERROR_CITY_REQUIRED,
        ADDRESSES_ERROR_ZIP_REQUIRED,
      } = MESSAGE_KEYS;

      try {
        validLine1 = inputLine1 !== null && inputLine1.length > 0;
        if (!validLine1) {
          errors.push(
            generateValidationError(ADDRESSES_ERROR_LINE1_REQUIRED, intl)
          );
        }
        validCity = inputCity !== null && inputCity.length > 0;
        if (!validCity) {
          errors.push(
            generateValidationError(ADDRESSES_ERROR_CITY_REQUIRED, intl)
          );
        }
        validZip =
          inputZip !== null &&
          inputZip.length >= VALIDATION_RULES.ZIP_MIN_LENGTH;
        if (!validZip) {
          errors.push(
            generateValidationError(ADDRESSES_ERROR_ZIP_REQUIRED, intl)
          );
        }
      } catch (validationException) {
        logger.warn("Exception on validateInput", validationException);
        errors.push(
          <span key="address_validation_exception">
            {validationException.message}
          </span>
        );
      } finally {
        this.setState({
          validCity,
          validLine1,
          validZip,
        });
        // Using state variables for this check will require clicks to create the element, as hooks are not updated instantly.
        if (errors.length === 0) {
          resolve({
            address: {
              addressId:
                selectedAddress.addressId && selectedAddress.addressId > 0
                  ? selectedAddress.addressId
                  : null,
              line1: inputLine1,
              peppolParticipantId: inputPeppolId === "" ? null : inputPeppolId,
              line2: inputLine2,
              countryProvince: inputCity,
              zipCode: inputZip,
              name: inputName,
              active: inputActive,
              searchKey: inputSearchkey,

              isHospital: inputIsHospital === true,
              isNursingHome: inputIsRetirement === true,
              isAmbulanceTransport: inputIsAmbulance === true,

              phone1: inputPhone1,
              phone2: inputPhone2,
              remark: inputComment,
            },
          });
        } else {
          reject(errors);
        }
      }
    });
  };

  /**
   * Sends the modified data to the server.
   * @param {Object} data
   * @returns {Promise<Object|String>}
   */
  sendData = (data) => {
    return new Promise((resolve, reject) => {
      try {
        let query = "";

        query = QUERIES.EDIT_ADDRESS;
        sendQuery(query, "post", data).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
        // TODO Handle offline action
      } catch (queryException) {
        logger.error(queryException);
        reject(queryException);
      }
    });
  };

  checkChangePending = (keyValue) => {
    let pending = false;
    const {
      selectedAddress: {
        line1,
        line2,
        countryProvince,
        active,
        zipCode,
        searchKey,

        isHospital,
        isNursingHome,
        isAmbulanceTransport,

        phone1,
        phone2,
        remark,
        peppolParticipantId,
      },
      setChangePending,
    } = this.props;
    try {
      let address = { ...this.state, ...keyValue };
      const {
        inputCity,
        inputLine1,
        inputPeppolId,
        inputLine2,
        inputZip,
        inputActive,
        inputSearchkey,

        inputIsAmbulance,
        inputIsHospital,
        inputIsRetirement,

        inputPhone1,
        inputPhone2,
        inputComment,
      } = address;
      pending =
        hasValueChanged(line1, inputLine1) ||
        hasValueChanged(line2, inputLine2) ||
        hasValueChanged(countryProvince, inputCity) ||
        hasValueChanged(zipCode, inputZip) ||
        hasValueChanged(active, inputActive) ||
        hasValueChanged(searchKey, inputSearchkey) ||
        hasValueChanged(isHospital, inputIsHospital) ||
        hasValueChanged(isNursingHome, inputIsRetirement) ||
        hasValueChanged(isAmbulanceTransport, inputIsAmbulance) ||
        hasValueChanged(phone1, inputPhone1) ||
        hasValueChanged(phone2, inputPhone2) ||
        hasValueChanged(remark, inputComment) ||
        hasValueChanged(peppolParticipantId, inputPeppolId);
    } catch (checkException) {
      logger.warn("Exception on check change pending", checkException);
      pending = true;
    } finally {      
      setChangePending(pending);
    }
  };

  handleSaveClick = () => {
    const { handleParentUpdate, intl, setChangePending } = this.props;
    this.validateInputs().then(
      (newAddressData) => {
        this.sendData(newAddressData).then(
          (response) => {
            if (response?.address?.addressId) {
              setChangePending(false);
              this.toast.show({
                severity: MESSAGE_SEVERITY.SUCCESS,
                summary: intl.formatMessage({
                  id: MESSAGE_KEYS.CUSTOMERS_SAVE_SUCCESS_MESSAGE,
                }),
              });

              handleParentUpdate(response.address.addressId);
            } else {
              logger.error("Response is empty.");
              this.toast.show({
                severity: MESSAGE_SEVERITY.ERROR,
                summary: this.props.intl.formatMessage({
                  id: MESSAGE_KEYS.ERROR_DATA_FETCH,
                }),
              });
            }
          },
          (error) => {
            logger.error(error);
            this.toast.show({
              severity: MESSAGE_SEVERITY.ERROR,
              summary:
                typeof error === "string"
                  ? error
                  : this.props.intl.formatMessage({
                      id: MESSAGE_KEYS.ERROR_DATA_FETCH,
                    }),
            });
          }
        );
      },
      (errors) => {
        this.toast.show({
          severity: MESSAGE_SEVERITY.WARNING,
          summary: this.props.intl.formatMessage({
            id: MESSAGE_KEYS.WARNING_VALIDATION_FAILED,
          }),
          detail: errors,
        });
      }
    );
  };

  renderInputs = () => {
    const { intl, selectedAddress } = this.props;
    const {
      inputCity,
      inputLine1,
      inputLine2,
      inputPeppolId,
      inputZip,
      inputName,
      inputActive,
      inputSearchkey,

      inputIsAmbulance,
      inputIsHospital,
      inputIsRetirement,

      inputPhone1,
      inputPhone2,
      inputComment,

      validCity,
      validLine1,
      validZip,
    } = this.state;
    const {
      CUSTOMERS_ADDRESS_LINE_1_LABEL,
      CUSTOMERS_ADDRESS_LINE_2_LABEL,
      CUSTOMERS_ADDRESS_CITY_LABEL,
      CUSTOMERS_ADDRESS_ZIP_LABEL,
      CUSTOMERS_ADDRESS_CREATE_BUTTON_LABEL,
      CUSTOMERS_FILTER_NAME,
      ADDRESSES_FILTER_SEARCHKEY_LABEL,

      ADDRESSES_HOSPITAL_LABEL,
      ADDRESSES_NURSING_LABEL,
      ADDRESSES_AMBULANCE_LABEL,

      ADDRESSES_PHONE_1_LABEL,
      ADDRESSES_PHONE_2_LABEL,
      APPOINTMENTS_COMMENT_LABEL,

      RESET_VALUES,
      ADDRESSES_SAVE_BUTTON_LABEL,
      ACTIVE,
      INACTIVE,
    } = MESSAGE_KEYS;

    let saveButtonkey = CUSTOMERS_ADDRESS_CREATE_BUTTON_LABEL;
    if (selectedAddress !== null && selectedAddress.addressId > 0) {
      saveButtonkey = ADDRESSES_SAVE_BUTTON_LABEL;
    }

    return (
      <div>
        <div className="p-fluid formgrid grid">
          <div className="p-field">
            <div className="p-fluid formgrid grid">
              <div className={`p-field col-${isDesktop ? "4" : "7"}`}>
                <FloatingTextInput
                  id={CUSTOMERS_FILTER_NAME}
                  value={inputName}
                  onChange={(event) =>
                    this.setState({ inputName: event.target.value }, () =>
                      this.checkChangePending({ inputName: event.target.value })
                    )
                  }
                  label={intl.formatMessage({ id: CUSTOMERS_FILTER_NAME })}
                />
              </div>

              <div className={`p-field col-${isDesktop ? "4" : "5"}`}>
                <FloatingTextInput
                  id={ADDRESSES_FILTER_SEARCHKEY_LABEL}
                  value={inputSearchkey}
                  onChange={(event) =>
                    this.setState({ inputSearchkey: event.target.value }, () =>
                      this.checkChangePending({
                        inputSearchkey: event.target.value,
                      })
                    )
                  }
                  label={intl.formatMessage({
                    id: ADDRESSES_FILTER_SEARCHKEY_LABEL,
                  })}
                />
              </div>

              <div className={`p-field col-${isDesktop ? "4" : "12"}`}>
                <FloatingTextInput
                  id={CUSTOMERS_ADDRESS_LINE_1_LABEL}
                  value={inputLine1}
                  onChange={(event) =>
                    this.setState({ inputLine1: event.target.value }, () =>
                      this.checkChangePending({
                        inputLine1: event.target.value,
                      })
                    )
                  }
                  label={intl.formatMessage({
                    id: CUSTOMERS_ADDRESS_LINE_1_LABEL,
                  })}
                  valid={validLine1}
                />
              </div>

              <div className={`p-field col-12`}>
                <FloatingTextInput
                  id={CUSTOMERS_ADDRESS_LINE_2_LABEL}
                  value={inputLine2}
                  onChange={(event) =>
                    this.setState({ inputLine2: event.target.value }, () =>
                      this.checkChangePending({
                        inputLine2: event.target.value,
                      })
                    )
                  }
                  label={intl.formatMessage({
                    id: CUSTOMERS_ADDRESS_LINE_2_LABEL,
                  })}
                />
              </div>

              <div className={`p-field col-6 dialog_row`}>
                <FloatingTextInput
                  id={CUSTOMERS_ADDRESS_ZIP_LABEL}
                  value={inputZip}
                  onChange={(event) =>
                    this.setState({ inputZip: event.target.value }, () =>
                      this.checkChangePending({ inputZip: event.target.value })
                    )
                  }
                  label={intl.formatMessage({
                    id: CUSTOMERS_ADDRESS_ZIP_LABEL,
                  })}
                  valid={validZip}
                />
              </div>
              <div className={`p-field col-6 dialog_row`}>
                <FloatingTextInput
                  id={CUSTOMERS_ADDRESS_CITY_LABEL}
                  value={inputCity}
                  onChange={(event) =>
                    this.setState({ inputCity: event.target.value }, () =>
                      this.checkChangePending({ inputCity: event.target.value })
                    )
                  }
                  label={intl.formatMessage({
                    id: CUSTOMERS_ADDRESS_CITY_LABEL,
                  })}
                  valid={validCity}
                />
              </div>

              <div className={`p-field col-${isDesktop ? "4" : "12"}`}>
                <FloatingTextInput
                  id={ADDRESSES_PHONE_1_LABEL}
                  value={inputPhone1}
                  onChange={(event) =>
                    this.setState({ inputPhone1: event.target.value }, () =>
                      this.checkChangePending({
                        inputPhone1: event.target.value,
                      })
                    )
                  }
                  label={intl.formatMessage({ id: ADDRESSES_PHONE_1_LABEL })}
                />
              </div>

              <div className={`p-field col-${isDesktop ? "4" : "12"}`}>
                <FloatingTextInput
                  id={ADDRESSES_PHONE_2_LABEL}
                  value={inputPhone2}
                  onChange={(event) =>
                    this.setState({ inputPhone2: event.target.value }, () =>
                      this.checkChangePending({
                        inputPhone2: event.target.value,
                      })
                    )
                  }
                  label={intl.formatMessage({ id: ADDRESSES_PHONE_2_LABEL })}
                />
              </div>

              <div className={`p-field col-${isDesktop ? "4" : "12"}`}>
                <FloatingTextInput
                  id={APPOINTMENTS_COMMENT_LABEL}
                  value={inputComment}
                  onChange={(event) =>
                    this.setState({ inputComment: event.target.value }, () =>
                      this.checkChangePending({
                        inputComment: event.target.value,
                      })
                    )
                  }
                  label={intl.formatMessage({ id: APPOINTMENTS_COMMENT_LABEL })}
                />
              </div>
              <div className={`p-field col-12`}>
                <FloatingTextInput
                  id={CUSTOMERS_ADDRESS_LINE_2_LABEL}
                  value={inputPeppolId}
                  onChange={(event) =>
                    this.setState({ inputPeppolId: event.target.value }, () =>
                      this.checkChangePending({
                        inputPeppolId: event.target.value,
                      })
                    )
                  }
                  label="Peppol ID"
                />

                {/*   TODO:            label={intl.formatMessage({
                    id: CUSTOMERS_ADDRESS_LINE_2_LABEL,
                  })} */}
              </div>
              <div className="formgrid my-3 grid col-12">
                <div className="col-4">
                  <Checkbox
                    inputId={ADDRESSES_HOSPITAL_LABEL}
                    checked={inputIsHospital}
                    onChange={(e) =>
                      this.setState({ inputIsHospital: e.checked })
                    }
                  />
                  <label className="ml-2" htmlFor={ADDRESSES_HOSPITAL_LABEL}>
                    {intl.formatMessage({ id: ADDRESSES_HOSPITAL_LABEL })}
                  </label>
                </div>

                <div className="col-4">
                  <Checkbox
                    inputId={ADDRESSES_NURSING_LABEL}
                    checked={inputIsRetirement}
                    onChange={(e) =>
                      this.setState({ inputIsRetirement: e.checked })
                    }
                  />
                  <label className="ml-2" htmlFor={ADDRESSES_NURSING_LABEL}>
                    {intl.formatMessage({ id: ADDRESSES_NURSING_LABEL })}
                  </label>
                </div>

                <div className="col-4">
                  <Checkbox
                    inputId={ADDRESSES_AMBULANCE_LABEL}
                    checked={inputIsAmbulance}
                    onChange={(e) =>
                      this.setState({ inputIsAmbulance: e.checked })
                    }
                  />
                  <label className="ml-2" htmlFor={ADDRESSES_AMBULANCE_LABEL}>
                    {intl.formatMessage({ id: ADDRESSES_AMBULANCE_LABEL })}
                  </label>
                </div>
              </div>
            </div>
            <div className="flex justify-content-between">
              <Button
                label={intl.formatMessage({
                  id: saveButtonkey,
                })}
                onClick={this.handleSaveClick}
              />
              <ToggleButton
                checked={inputActive}
                offIcon="pi pi-times"
                offLabel={intl.formatMessage({ id: INACTIVE })}
                onIcon={"pi pi-check"}
                onLabel={intl.formatMessage({ id: ACTIVE })}
                onChange={(e) =>
                  this.setState({ inputActive: e.value }, () => {
                    this.checkChangePending({ inputActive: e.value });
                  })
                }
                className="mx-3"
              />
              <Button
                label={intl.formatMessage({ id: RESET_VALUES })}
                onClick={this.resetState}
                className="p-button-warning"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <div>
        <Toast ref={(el) => (this.toast = el)} />
        {this.renderInputs()}
      </div>
    );
  };
}

export default connect(null, { setChangePending })(
  injectIntl(AddressEditLayout)
);
