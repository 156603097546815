/**
 * The dialog to generate Peppol exports
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React, { useEffect, useRef, useState } from "react";
// PrimeReact components
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { MultiSelect } from "primereact/multiselect";

// Localization
import { useIntl } from "react-intl";
import {
  MESSAGE_KEYS,
  MESSAGE_SEVERITY,
  QUERIES,
} from "assets/staticData/enums";
import { dateToQueryString, sendQuery } from "common/Helpers";
import { connect } from "react-redux";
import {
  setPeppolExporterTaskActive,
  setPeppolInvoicesToProceeed,
} from "actions/index";

const BillPeppolExportDialog = (props) => {
  const intl = useIntl();
  const { onHide, visible } = props;
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [availableAddresses, setAvailableAddresses] = useState(null);
  const [selectedAddresses, setSelectedAddresses] = useState(null);
  const [resend, setResend] = useState(false);
  const [pending, setPending] = useState(false);

  const toast = useRef(null);

  useEffect(() => {
    getPeppolAddresses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExport = () => {
    const { setPeppolExporterTaskActive, setPeppolInvoicesToProceeed } = props;

    let selectedAddressesIds = null;

    // map the ids for request
    if (selectedAddresses?.length > 0) {
      selectedAddressesIds = selectedAddresses
        .map((address) => {
          return address?.addressId;
        })
        .join(",");
    }

    setPending(true);

    const params = {
      from: dateToQueryString(fromDate),
      to: dateToQueryString(toDate),
      resend,
      addressId: selectedAddressesIds,
    };

    const {
      BILLS_PEPPOL_EXPORT_FAIL,
      ERROR,
      BILLS_PEPPOL_NO_INVOICES_FOUND,
      DIALOG_WARNING_TITLE,
    } = MESSAGE_KEYS;
    sendQuery(
      `${QUERIES.EXPORT_PEPPOL_ALL}?startDatum=${dateToQueryString(
        fromDate
      )}&endDatum=${dateToQueryString(toDate)}&resend=${resend}${
        selectedAddressesIds ? "&addressIds=" + selectedAddressesIds : ""
      }`,
      "GET",
      params
    )
      .then(
        (response) => {
          setPending(false);

          if (response && response?.length > 0) {
            // map invoices from backend into redux
            setPeppolInvoicesToProceeed(response);
            // activate exporter task
            setPeppolExporterTaskActive(true);
            // reset inputs and hide dialog
            resetState();
            onHide();
          } else {
            // oh oh, no invoices.
            if (toast.current) {
              toast.current.show({
                severity: MESSAGE_SEVERITY.WARNING,
                summary: intl.formatMessage({ id: DIALOG_WARNING_TITLE }),
                detail: intl.formatMessage({
                  id: BILLS_PEPPOL_NO_INVOICES_FOUND,
                }),
              });
            }
          }
        },
        (error) => {
          console.warn("Could not export peppol bills due to:", error);
          if (toast.current) {
            toast.current.show({
              severity: MESSAGE_SEVERITY.ERROR,
              summary: intl.formatMessage({ id: ERROR }),
              detail: intl.formatMessage({ id: BILLS_PEPPOL_EXPORT_FAIL }),
            });
          }
        }
      )
      .finally(() => {
        setPending(false);
      });
  };

  const footer = () => {
    const { onHide } = props;
    const { BILLS_PEPPOL_EXPORT_LABEL, DIALOG_CANCEL_BUTTON_LABEL } =
      MESSAGE_KEYS;

    return (
      <div className="flex justify-content-between mb-2">
        <Button
          label={intl.formatMessage({ id: BILLS_PEPPOL_EXPORT_LABEL })}
          onClick={handleExport}
          loading={pending || props?.isPeppolTaskActive}
        />
        <Button
          label={intl.formatMessage({ id: DIALOG_CANCEL_BUTTON_LABEL })}
          onClick={onHide}
          loading={pending || props?.isPeppolTaskActive}
        />
      </div>
    );
  };

  const resetState = () => {
    setFromDate(new Date());
    setToDate(new Date());
    setResend(false);
    setSelectedAddresses(null);
  };

  const getPeppolAddresses = () => {
    const { ERROR } = MESSAGE_KEYS;

    setPending(true);

    sendQuery(QUERIES.GET_PEPPOL_ADDRESSES, "get")
      .then((response) => {
        if (response && response.length > 0) {
          // map as following: name | address line 1
          response.forEach((address) => {
            address.fullname = `${address?.name} ${
              address?.address ? `| ${address?.address}` : ""
            }`;
          });
        }
        setAvailableAddresses(response);
      })
      .catch((error) => {
        if (toast.current) {
          toast.current.show({
            severity: MESSAGE_SEVERITY.ERROR,
            summary: intl.formatMessage({ id: ERROR }),
            detail: error,
          });
        }
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <Dialog
      header={intl.formatMessage({ id: MESSAGE_KEYS.BILLS_EXPORT_TO_PEPPOL })}
      footer={footer()}
      visible={visible}
      onHide={() => {
        resetState();
        onHide();
      }}
      style={{ maxWidth: "500px" }}
    >
      <Toast ref={toast} />
      <div className="mt-3 w-100">
        <div className="flex justify-content-between">
          <div>
            <label htmlFor="peppol_export_from">
              {intl.formatMessage({ id: MESSAGE_KEYS.BILLS_FILTER_FROM_DATE })}
            </label>
            <Calendar
              value={fromDate}
              dateFormat="dd/mm/yy"
              onChange={(e) => setFromDate(e.value)}
              id="peppol_export_from"
              selectOtherMonths={true}
            />
          </div>
          <div>
            <label htmlFor="peppol_export_to">
              {intl.formatMessage({ id: MESSAGE_KEYS.BILLS_FILTER_TO_DATE })}
            </label>
            <Calendar
              value={toDate}
              dateFormat="dd/mm/yy"
              onChange={(e) => setToDate(e.value)}
              id="peppol_export_to"
              selectOtherMonths={true}
            />
          </div>
        </div>
        <div className="mt-3 w-100">
          <div className="flex justify-content-between ">
            <div>
              <label htmlFor="peppol_export_addresses">
                {intl.formatMessage({
                  id: MESSAGE_KEYS.BILLS_PEPPOL_ADDRESSES_LABEL,
                })}
              </label>
              <br />
              <MultiSelect
                value={selectedAddresses}
                onChange={(e) => setSelectedAddresses(e.value)}
                options={availableAddresses}
                optionLabel="fullname"
                placeholder={intl.formatMessage({
                  id: MESSAGE_KEYS.BILLS_PEPPOL_SELECT_ADDRESSES_LABEL,
                })}
                id="peppol_export_addresses"
                display="chip"
              />
            </div>
          </div>
        </div>
        <div className="mt-3">
          <Checkbox
            inputId="peppol_resend"
            checked={resend}
            onChange={(e) => setResend(e.checked)}
          />
          <label className="ml-2" htmlFor="peppol_resend">
            {intl.formatMessage({ id: MESSAGE_KEYS.BILLS_PEPPOL_RESEND_LABEL })}
          </label>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    isPeppolTaskActive: state.application?.isPeppolExporterTaskActive,
  };
};

export default connect(mapStateToProps, {
  setPeppolExporterTaskActive,
  setPeppolInvoicesToProceeed,
})(BillPeppolExportDialog);
